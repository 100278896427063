import React, { useEffect, useState } from "react";
import { Contacts } from "components/Contacts/Contacts";
import { Loader } from "components/Loader/Loader";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { SmsCampaigns } from "./SmsCampaigns/SmsCampaigns";
import { SmsLists } from "./SmsLists/SmsLists";
import { useSmsListService } from "hooks/services/useSmsListService";
import { useSmsService } from "hooks/services/useSmsService";
import { Contact } from "models/Contact";
import "./SmsList.scss";
import { FormFields } from "./FormFields/FormFields";
import { TabPanel, TabView } from "primereact/tabview";

enum Tab {
  none = -1,
  contacts = 0,
  messages = 1,
};

export const SmsList = () => {

  const smsService = useSmsService();
  const smsListService = useSmsListService();

  const [tabIndex, setTabIndex] = useState<Tab>(Tab.none);

  const toggleContact = async (contact: Contact, checked: boolean) => {
    if (!smsListService.currentSmsList) {
      return;
    }
    if (checked) {
      await smsListService.addContactToList(contact, smsListService.currentSmsList);
    } else {
      await smsListService.removeContactFromList(contact, smsListService.currentSmsList);
    } 
  }

  const listSelected = Boolean(smsListService.currentSmsList);
  const currentListHasContacts = (smsListService.currentSmsList?.contactData || []).length > 0;

  useEffect(() => {
    setTabIndex(!listSelected ? Tab.none :
      (tabIndex !== Tab.none ? tabIndex : (currentListHasContacts ? Tab.messages : Tab.contacts))
    );
  }, [listSelected, currentListHasContacts, tabIndex]);

  return (
    <div className="sms-list-component">
      <Splitter>
        <SplitterPanel size={20} className="auto-flex">
          <SmsLists />
        </SplitterPanel>
        <SplitterPanel size={20} className="auto-flex">
          {smsListService.currentSmsList && <>
            <TabView activeIndex={tabIndex} onTabChange={e => setTabIndex(e.index)}>
              <TabPanel header="Recepients">
                <Splitter>
                  <SplitterPanel size={25} className="auto-flex">
                    {smsListService.currentSmsList && <>
                      {smsService.isReady && (!smsListService.isReady || smsListService.smsListsLoading) && <Loader />}
                      <Contacts
                        list={smsListService.currentSmsList}
                        toggleContact={toggleContact}
                      />                
                    </>}
                  </SplitterPanel>
                  <SplitterPanel className="auto-flex">
                    <FormFields />
                  </SplitterPanel>
                </Splitter>
              </TabPanel>
              <TabPanel header="Messages">
                <SmsCampaigns />
              </TabPanel>
            </TabView>
          </>}
        </SplitterPanel>
      </Splitter>
    </div>
  );

};
