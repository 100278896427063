import React, { useCallback, useEffect, useState } from 'react';
import { useCallService } from 'hooks/services/useCallService';
import { useContactService } from 'hooks/services/useContactService';
import { useMyNumberService } from 'hooks/services/useMyNumberService';
import { useTimer } from 'hooks/useTimer';
import { CallState, Direction } from 'models/Message';
import { Button } from 'primereact/button';
import { FaMicrophoneAltSlash, FaPhoneSlash } from 'react-icons/fa';
import { HiOutlinePause } from 'react-icons/hi';
import { formatTimer } from 'helpers/date';
import './ActiveCall.scss';

export const ActiveCall = () => {
  const callService = useCallService();
  const contactService = useContactService();
  const myNumberService = useMyNumberService();

  const call = callService.currentCall;

  const timerEnabled = true; // call?.callState === CallState.ongoing;
  const [initialTimerTime, setInitialTimerTime] = useState(0);

  useEffect(() => {
    setInitialTimerTime(call?.callState === CallState.ongoing && call.callStartedAt
      ? new Date().getTime() - call.callStartedAt
      : 0
    );
  }, [call?.callState, call?.callStartedAt]);

  const mutedClick = useCallback(() => callService.muted = !callService.muted, [callService]);
  const onHoldClick = useCallback(() => callService.onHold = !callService.onHold, [callService]);
  const onHangUpClick = useCallback(() => callService.hangUp(), [callService]);

  const currentTime = useTimer({ enabled: timerEnabled, startTime: initialTimerTime });

  // if (!call) {
  //   return <em className="active-call-component">No active call</em>;
  // }

  const contact = contactService.getContactByNumber(call?.contactNumber || '')!;
  const myNumber = myNumberService.myNumbers.find(n => n.number === call?.myNumber);
  
  return (
    <div className="active-call-component auto-flex">
      <div className="content">
        <div className="my-nuumber">{call?.direction === Direction.in ? 'To' : 'From'} {myNumber?.name}</div>
        {contact && <div className="contact-number">{contact.name}</div>}
        <p className="my-5">
          {call?.callState === CallState.connecting && <>Connecting...</>}
          {call?.callState === CallState.ringing && <>Ringing...</>}
          {(call?.callState === CallState.ongoing || call?.callState === CallState.ended) &&
            <div className="d-flex flex-column align-items-center">
              <div>{formatTimer(currentTime)}</div>
            </div>
          }
        </p>
        <div className="session-controls d-flex justify-content-center align-items-center">
          <div className="button-row">
            <Button
              className={`p-button-rounded ${callService.muted ? 'p-button-warning' : 'p-button-secondary'}`}
              onClick={mutedClick}
              title="Mute/unmute"
            ><FaMicrophoneAltSlash /></Button>
            <Button
              className="p-button-rounded p-button-danger"
              onClick={onHangUpClick}
              title="Hang up"
            ><FaPhoneSlash /></Button>
            <Button
              className={`p-button-rounded ${callService.onHold ? 'p-button-warning' : 'p-button-secondary'}`}
              onClick={onHoldClick}
              title="Hold/unhold"
            ><HiOutlinePause /></Button>
          </div>
        </div>
      </div>
    </div>
  );
};
