import { useEffect, useState } from "react";

export type Updates = Set<React.Dispatch<React.SetStateAction<number>>>;

export function useManageUpdates(updates: Updates) {
  const [, update] = useState(0);
  useEffect(() => {
    updates.add(update);
    return () => {
      updates.delete(update);
    }
  }, [updates]);
};
