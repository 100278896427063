import React, { useState } from 'react';
import './Login.sass';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from 'hooks/services/useAuthService';
import { Loader } from 'components/Loader/Loader';
import { useNavigationService } from 'hooks/services/useNavigationService';
import { showToast } from 'helpers/toast';
import { pageInfo } from 'models/navigation';

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const auth = useAuthService();
  const navigate = useNavigate();
  const navigation = useNavigationService();

  const login = async () => {
    try {
      setLoading(true);
      await auth.login(username, password);
    } catch (e) {
      showToast({
        severity: 'error',
        summary: 'Login failed',
        detail: 'Incorrect username or password',
      });
    } finally {
      setLoading(false);
    }

    navigate(pageInfo[navigation.page].route);
  };

  return (
    <div className="login-page">
      <form>
        <h1>Web Phone</h1>
        <div className="form-group">
          <label>Username</label>
          <input type="text" className="textbox" name="username" value={username} onChange={e => setUsername(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input type="password" className="textbox" name="password" value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        {loading ? <Loader /> : <button className="button" onClick={e => { e.preventDefault(); login(); }}>Login</button>}
      </form>
    </div>
  );
};
