import _ from 'lodash-es';
import { storageKeys } from 'const/storage-keys';
import { getStorageItem, setStorageItem } from 'helpers/storage';
import { Message } from 'models/Message';
import { AppMode, Page, pagesByMode } from 'models/navigation';
import { Updates, useManageUpdates } from '../useManageUpdates';

export class NavigationService {

  private currentMode: AppMode = getStorageItem(storageKeys.navigation.mode) || AppMode.home;
  private currentPageByMode: Record<AppMode, Page> = getStorageItem(storageKeys.navigation.page) ||
    _.fromPairs(_.toPairs(pagesByMode).map(([mode, pages]) => [
      mode,
      pages[0] === Page.callHistory && !getStorageItem<Message[]>(storageKeys.calls.messages)?.length
        ? pages[1] // show dialer instead of empty call history
        : pages[0],
    ]))

  public readonly ready: Promise<void>;
  public isReady = false;
  public updates = 0; // is incremented by update()

  constructor() {
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    setStorageItem(storageKeys.navigation.mode, this.currentMode);
    setStorageItem(storageKeys.navigation.page, this.currentPageByMode);
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
  }

  public get mode(): AppMode {
    return this.currentMode;
  }
  public set mode(value: AppMode) {
    if (this.mode === value) {
      return;
    }
    this.currentMode = value;
    this.update();
  }

  public get page(): Page {
    return this.currentPageByMode[this.mode];
  }
  public set page(value: Page) {
    if (this.page === value) {
      return;
    }
    this.currentPageByMode[this.mode] = value;
    this.update();
  }
}

let navigationService: NavigationService;
let updates: Updates = new Set();

export function useNavigationService(): NavigationService {
  useManageUpdates(updates);
  navigationService = navigationService || new NavigationService();
  return navigationService;
}
