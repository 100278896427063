import React from 'react';
import { Media } from 'models/Media';
import { Button } from 'primereact/button';
import { downloadFile } from 'helpers/file';
import "./MediaMessageContent.scss";

export const MediaMessageContent = ({ media }: { media: Media }) => {

  if (!media?.url) {
    return null;
  }
  return (
    <div className="media-message-content-component">
      {(media.mimeType || '').startsWith('image') ?
        <div className="image"><img className="clickable" src={media.url} alt={media.description} onClick={() => downloadFile(media.url || '')} /></div>
      :
        <Button className="p-button-rounded" onClick={() => downloadFile(media.url || '')}>Download</Button>
      }
    </div>
  );

}
