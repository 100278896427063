import React, { useEffect } from 'react';
import './Login.sass';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from 'hooks/services/useAuthService';
import { routes } from 'const/routes';
import { Loader } from 'components/Loader/Loader';

export const AutoLogin = () => {

  const authService = useAuthService();
  const navigate = useNavigate();

  useEffect(() => {
    authService.autoLogin().then(token => token ? navigate(-1) : navigate(routes.login));
  }, [authService, navigate]);

  return <Loader />;

};
