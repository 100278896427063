export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = String(phoneNumberString).replace(/[^0-9+*#]/g, '');
  let number = cleaned.replace(/[#*].*$/, '');
  let suffix = cleaned.substring(number.length);
  suffix = suffix ? ` ${suffix}` : '';
  let usPrefix = '';
  if (number.startsWith('1') || number.startsWith('+1')) {
    usPrefix = number.substring(0, number.indexOf('1') + 1);
    number = number.substring(usPrefix.length);
  }
  if ((number.startsWith('+') && !usPrefix) || number.length > 10) {
    return `${usPrefix}${number}${suffix}`;
  }
  if (usPrefix || (number.length > 7)) {
    let result = usPrefix;
    if (number.length) {
      result = `${result} (${number.substring(0, 3)})`
      number = number.substring(3);
    }
    if (number.length) {
      result = `${result} ${[number.substring(0, 3), number.substring(3)].filter(Boolean).join('-')}`;
    }
    return `${result}${suffix}`;
  }
  return `${[number.substring(0, 3), number.substring(3)].filter(Boolean).join('-')}${suffix}`;
};

export const toDigitalNumber = (rawNumber: string): string =>
  rawNumber.replace(/[ABC]/ig, '2')
    .replace(/[DEF]/ig, '3')
    .replace(/[GHI]/ig, '4')
    .replace(/[JKL]/ig, '5')
    .replace(/[MNO]/ig, '6')
    .replace(/[PQRS]/ig, '7')
    .replace(/[TUV]/ig, '8')
    .replace(/[WXYZ]/ig, '9')
    .replace(/[^0-9+*#]/g, '')
    .replace(/^[+]?1/, '');
