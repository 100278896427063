import React from 'react';
import { DataTable, DataTableRowClickEventParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAuthService } from 'hooks/services/useAuthService';
import { useLogout } from 'hooks/useLogout';
import { useMyNumberService } from 'hooks/services/useMyNumberService';
import { Feature, featureInfo, features, MyNumber } from 'models/MyNumber';
import { Loader } from 'components/Loader/Loader';
import { showToast } from 'helpers/toast';
import { useChatService } from 'hooks/services/useChatService';
import { useNavigationService } from 'hooks/services/useNavigationService';
import { delay } from 'helpers/delay';
import './Home.sass';

const featureClassPrefix = 'feature-';

export const Home = () => {

  const navigation = useNavigationService();
  const authService = useAuthService();
  const myNumberService = useMyNumberService();
  const chatService = useChatService(); // includes callService, smsSerivce, voicemailService, mediaService, myNumberService, contactService

  const logout = useLogout();

  const onRowClick = async (e: DataTableRowClickEventParams) => {
    const classList: string[] = Array.from((e.originalEvent.target as HTMLElement).classList);
    const feature = classList.find(c => c.startsWith(featureClassPrefix))?.substring(featureClassPrefix.length) as Feature;
    if (!feature) {
      return;
    }
    const myNumber = e.data as MyNumber;
    if (!myNumber.features.includes(feature)) {
      showToast({
        severity: 'warn',
        summary: 'Disabled feature',
        detail: `Sorry, unfortunately ${featureInfo[feature].name} are disabled for ${myNumber.name}.`,
      });
      return;
    }
    myNumberService.setCurrent(myNumber.number);
    await delay(500);
    navigation.mode = featureInfo[feature].mode;
  }

  return (
    <div className="home-page">
      {!authService.isAutoLoggedIn &&
        <header>
          <h1>Web Phone</h1>
          <button className="button" onClick={logout}>Logout</button>
        </header>
      }
      <h2>Apeiron Communicator – Web</h2>
      <div className="description">SMS and Voice communications User Interface for the browser environment</div>
      <div className="number-features">
        {myNumberService.isReady || myNumberService.myNumbers.length ?
          <>
            {!chatService.isReady && <Loader />}
            <DataTable value={myNumberService.myNumbers} onRowClick={onRowClick}>
              <Column field="number" header="Phone Number" body={myNumber =>
                <div className={`number ${myNumber.number === myNumberService.current?.number ? 'active' : ''}`}>
                  {myNumber.name}
                </div>
              }></Column>
              {features.filter(myNumberService.hasFeature).map(feature => {
                const featureClass = `${featureClassPrefix}${feature}`;
                return (
                  <Column key={feature} field={feature} header={featureInfo[feature].name} className={featureClass} body={(myNumber: MyNumber) => {
                    const ready =
                      feature === Feature.calls ? myNumber.calls?.ready :
                      feature === Feature.sms ? myNumber.sms?.ready :
                      feature === Feature.mms ? myNumber.mms?.ready :
                      true;
                    return !myNumber.features.includes(feature) ? null :
                      <div className={`status ${ready ? 'connected' : 'disconnected'} ${featureClass}`}>
                        {ready ? 'Connected' : 'Disconnected'}
                      </div>
                  }}></Column>  
                );
              })}
            </DataTable>
          </>
          : <Loader />
        }
      </div>
    </div>
  );

};
