import React from 'react';
import { Route, Routes } from 'react-router';
import { routes } from './const/routes';
import { AuthGuard } from './guards/AuthGuard';
import { BasicLayout } from './layout/BasicLayout/BasicLayout';
import { Login } from './pages/auth/Login';
import { AutoLogin } from './pages/auth/AutoLogin';
import { Logout } from './pages/auth/Logout';
import { AppLayout } from './layout/AppLayout/AppLayout';
import { modes, Page, pageInfo, pagesByMode } from './models/navigation';
import { Home } from './pages/Home/Home';
import { Messages } from './pages/chats/Messages/Messages';
import { Dialer } from './pages/calls/Dialer/Dialer';
import { ActiveCall } from './pages/calls/ActiveCall/ActiveCall';
import { SmsList } from './pages/sms/SmsList/SmsList';
import { Greetings } from './pages/voicemails/Greetings/Greetings';
import { AutoResponder } from 'pages/sms/AutoResponder/AutoResponder';
import { SmsTemplates } from 'pages/sms/Templates/Templates';
import { Gallery } from 'pages/sms/Gallery/Gallery';

const pageComponents: Record<Page, React.FC> = {
  [Page.home]: () => <Home />,
  [Page.chats]: () => <Messages />,
  [Page.callHistory]: () => <Messages />,
  [Page.dialer]: () => <Dialer />,
  [Page.activeCall]: () => <ActiveCall />,
  [Page.sms]: () => <Messages />,
  [Page.smsTemplates]: () => <SmsTemplates />,
  [Page.smsList]: () => <SmsList />,
  [Page.autoResponder]: () => <AutoResponder />,
  [Page.gallery]: () => <Gallery />,
  [Page.voicemails]: () => <Messages />,
  [Page.greetings]: () => <Greetings />,
};

export const AppRoutes = () => (
  <BasicLayout>
    <Routes>
      <Route path={routes.login} element={<Login />}></Route>
      <Route path={routes.autoLogin} element={<AutoLogin />}></Route>
      <Route path={routes.logout} element={<Logout />}></Route>
      {modes.map(mode => pagesByMode[mode].map(page => {
        const PageComponent = pageComponents[page];
        return (
          <Route path={pageInfo[page].route} element={
            <AuthGuard><AppLayout mode={mode} page={page}><PageComponent /></AppLayout></AuthGuard>
          }></Route>
        );
      }))}
      <Route path='*' element={<div>404 Not found</div>}></Route>
    </Routes>
  </BasicLayout>
);
