import { storageKeys } from 'const/storage-keys';
import { ApiService, useApiService } from './useApiService';
import { getStorageItem, setStorageItem } from 'helpers/storage';
import { urls } from 'const/urls';
import { Id } from 'models/common';
import { MediaService, useMediaService } from './useMediaService';
import { Direction, Message, MessageType } from 'models/Message';
import { ContactService, useContactService } from './useContactService';
import { MyNumberService, useMyNumberService } from './useMyNumberService';
import { Feature } from 'models/MyNumber';
import { Updates, useManageUpdates } from '../useManageUpdates';
import { environment } from 'environments';
import { generateGuid } from 'helpers/guid';
import { getToken } from 'helpers/token';
import { AudioService, useAudioService } from './useAudioService';
import _ from 'lodash-es';
import { showToast } from 'helpers/toast';
import { formatPhoneNumber } from 'helpers/phone';

export class MmsService {

  private mmsHistory: Record<Id, Message[]> = getStorageItem(storageKeys.mms.messages) || {}; // key is contactId; messages are sorted `at desc`

  // WARNING: don't forget to care about data index integrity!
  private messageById: Record<Id, Message> = _.fromPairs(_.flatten(Object.values(this.mmsHistory)).filter(m => m.entityId).map(m => [m.entityId, m]));
  private messagesByNumbers: Record<Id, Message[]> = // key is [myNumber, contactNumber].join('-')
    _.groupBy(_.flatten(Object.values(this.mmsHistory)), (m: Message) => `${m.myNumber}-${m.contactNumber}`);

  private ws: WebSocket | undefined;

  public readonly ready: Promise<void>;
  public isReady = false;
  public updates = 0; // is incremented by update()

  private readonly api: ApiService;
  private readonly audio: AudioService;
  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;
  private readonly mediaService: MediaService;

  constructor({ api, audio, myNumberService, contactService, mediaService }: { api: ApiService, audio: AudioService, myNumberService: MyNumberService, contactService: ContactService, mediaService: MediaService }) {
    this.api = api;
    this.audio = audio;
    this.myNumberService = myNumberService;
    this.contactService = contactService;
    this.mediaService = mediaService;
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    setStorageItem(storageKeys.mms.messages, this.mmsHistory);
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
    await Promise.all([this.myNumberService.ready, this.contactService.ready]);
    const myMmsNumbers = this.myNumberService.myNumbers.filter(n => n.features.includes(Feature.mms));
    await Promise.all([
      this.loadMessages(),
      this.initWebSocket(),
    ]);
    for (const myNumber of myMmsNumbers) {
      myNumber.mms!.ready = true;
    }
    this.myNumberService.update();
  }

  public getMessagesByContactId(contactId: Id): Message[] {
    return this.mmsHistory[contactId] || [];
  }

  public getAllMessages(): Message[] {
    return _.sortBy(_.flatten(Object.values(this.mmsHistory)), 'at', 'desc');
  }

  public getContactIdByMedia(mediaId: Id): Id | undefined {
    if (!mediaId) {
      return undefined;
    }
    return _.toPairs(this.mmsHistory)
      .filter(([, messages]) => messages.some(m => m.mediaId === mediaId))
      .map(([contactId]) => contactId)[0];
  }

  private async loadMessages(): Promise<void> {
    const myMmsNumbers = this.myNumberService.myNumbers.filter(n => n.features.includes(Feature.mms));
    await Promise.all(myMmsNumbers.map(myNumber => this.loadMessagesByMyNumber(myNumber.number)));
  }

  private async loadMessagesByMyNumber(myNumber: string): Promise<void> {
    interface Response {
      count: number;
      next?: string;
      results: Array<{
        date_received: string; // "2019-08-24T14:15:22Z",
        message_id: string; // "\"1696327233@1-mms1.mmsnni.mgw.iqntusa.net\":2"
        from_number: string;
        to_number: string;
        priority: number;
        coding: number;
        validity: string; // "2019-08-24T14:15:22Z",
        content: string; // text content, if any, otherwise null
        attachment_url: string; // URL, if a file attached
        attachment_mime_type: string;
        attachment_file_name: string;
      }>;
    }
    const firstPageUrl = urls.mmsMessages.replace(':myNumber', myNumber);
    const countKey = storageKeys.mms.count.replace(':myNumber', myNumber);
    let url = firstPageUrl;
    do {
      const response = await this.api.get<Response>(url);
      if (url === firstPageUrl) { // page 1
        if (response.count === getStorageItem<number>(countKey)) {
          break;
        }
        setStorageItem(countKey, response.count || 0);
      }
      const newMms: Message[] = response.results.filter(x => !this.messageById[x.message_id]).map(x => {
        const at = new Date(x.date_received).getTime();
        const media =
          this.mediaService.getMediaByUrl(x.attachment_url) ||
          this.mediaService.addNewMedia({
            id: x.message_id,
            url: x.attachment_url,
            mimeType: x.attachment_mime_type,
            fileName: x.attachment_file_name,
          });
        return {
          myNumber: x.to_number,
          contactNumber: x.from_number,
          direction: Direction.in,
          type: MessageType.mms,
          entityId: x.message_id,
          at,
          body: x.content || '',
          sentAt: at,
          mediaId: media.id,
        } as Message;
      });
      for (const mms of newMms) {
        this.addNewMms(mms);
      }
      url = response.next || '';
    } while (url);
    this.update();
  }

  private async initWebSocket() {
    const accessToken = getToken()?.accessToken;
    if (!accessToken) {
      return;
    }
    interface WebSocketMessage {
      jsonrpc: '2.0';
      data: {
        type: 'event_sms_received' | 'event_sms_sent';
        from: string;
        to: string;
        mms: boolean;
      };
    }
    this.ws = new WebSocket(environment.sms.webSocket.replace('{ACCESS_TOKEN}', accessToken));
    this.ws.onopen = () => {
      console.log('MMS webSocket opened');

      this.ws!.onmessage = async (event: { data: string }) => {
        const webSocketMessage = JSON.parse(event.data) as WebSocketMessage;
        const { data } = webSocketMessage;
        console.log('WebSocket INCOMING MMS MESSAGE', webSocketMessage);
        if (!data.mms) {
          return;
        }
        const direction =
          data.type === 'event_sms_received' ? Direction.in :
          data.type === 'event_sms_sent' ? Direction.out :
          '';
        if (!direction) {
          return;
        }
        if (direction === Direction.in) {
          this.audio.play('incomingSms');
        }
        const myNumber = data.to;
        const contactNumber = data.from;
        if (!this.myNumberService.myNumbers.find(n => n.number === myNumber)) {
          console.error(`My number ${myNumber} not found`);
          return;
        }
        if (direction === Direction.in) {
          showToast({
            severity: 'info',
            summary: `MMS from ${formatPhoneNumber(contactNumber)}`,
            detail: '',
          });
        }
        await this.loadMessagesByMyNumber(myNumber);
      };

      this.ws!.onerror = event => console.log('On MMS WebSocket error', event);
      this.ws!.onclose = event => console.log('On MMS WebSocket close', event);
    };
  }

  private async addNewMms(message: Message) {
    const { myNumber, contactNumber } = message;
    if (message.type && message.type !== MessageType.mms) {
      throw new Error(`Cannot add MMS message with messageType ${message.type}`);
    }
    if (!myNumber || !contactNumber) {
      throw new Error('myNumber and contactNumber should be provided for the new message');
    }
    message.entityId = message.entityId || generateGuid();
    const contact = this.contactService.getContactByNumber(message.contactNumber) || this.contactService.addNewContact(message.contactNumber, message);
    contact.hasMms = true;
    this.mmsHistory[contact.id] = this.mmsHistory[contact.id] || [];
    this.mmsHistory[contact.id].unshift(message);
    this.messageById[message.entityId] = message;
    const numbersKey = [message.myNumber, message.contactNumber].join('-');
    this.messagesByNumbers[numbersKey] = this.messagesByNumbers[numbersKey] || [];
    this.messagesByNumbers[numbersKey].push(message);
    this.update();
  }

  get totalMessageCount(): number {
    return _.flatten(Object.values(this.mmsHistory)).length;
  }
}

let mmsService: MmsService;
let updates: Updates = new Set();

export function useMmsService(): MmsService {
  useManageUpdates(updates);
  const api = useApiService();
  const audio = useAudioService();
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  const mediaService = useMediaService();
  mmsService = mmsService || new MmsService({ api, audio, myNumberService, contactService, mediaService });
  return mmsService;
}
