import { Token } from 'models/Token';
import { ApiService, useApiService } from './useApiService';
import { environment } from 'environments';
import {
  getToken,
  anonymousToken,
  getCustomerNumber,
} from 'helpers/token';
import { Account, emptyAccount } from 'models/Account';
import { forceReload } from 'helpers/url';
import { storageKeys } from 'const/storage-keys';
import { urls } from 'const/urls';
import { setStorageItem } from 'helpers/storage';
import _ from 'lodash-es';

const tokenExpiration = 5; // minutes

interface AuthResponse {
  access: string;
  refresh: string;
}

interface InitResponse extends AuthResponse {
  customer_number: number;
}

export class AuthService {

  public account: Account = _.cloneDeep(emptyAccount);
  private cachedCustomData: any = undefined;

  private _isAutoLoggedIn = false;
  public get isAutoLoggedIn(): boolean {
    return this._isAutoLoggedIn;
  }

  private initPromise: Promise<void> | null = null;

  public readonly ready: Promise<void>;
  public isReady = false;

  protected refreshThread: NodeJS.Timeout | null = null;

  private readonly api: ApiService;

  constructor({ api }: { api: ApiService }) {
    this.api = api;
    this.refreshTokens = this.refreshTokens.bind(this);
    this.ready = this.init().then((() => {
      this.isReady = true;
    }));
  }

  public async init(): Promise<void> {
    if (!this.initPromise) {
      this.initPromise = this.initInternal();
    }
    return this.initPromise;
  }

  private async initInternal() {
    const wasAutoLoggedIn = Boolean(getCustomerNumber());
    const autoToken = await this.autoLogin();
    if (Number(autoToken) + Number(wasAutoLoggedIn) === 1) { // autoToken XOR wasAutoLoggedIn
      localStorage.clear();
      sessionStorage.clear();
    }
    if (!autoToken && getToken().refreshToken) {
      this.refreshTokens();
    }
    if (getToken().accessToken) {
      await this.afterLogin();
    }
  }

  private setToken(token: Token) {
    setStorageItem(storageKeys.auth.token, token);
    if (this.refreshThread) {
      clearTimeout(this.refreshThread);
    }
    this.refreshThread = setTimeout(this.refreshTokens, (tokenExpiration - 0.5) * 60000);
  }

  // noinspection JSMethodCanBeStatic
  private setCustomerNumber(customerNumber: number) {
    setStorageItem(storageKeys.auth.customerNumber, customerNumber || '');
  }

  async autoLogin(): Promise<Token | undefined> {
    try {
      const response = await this.api.post<InitResponse>(
        environment.api.autoLoginUrl,
        '',
        { allowAnonymous: true },
      );
      const token = {
        accessToken: response.access,
        refreshToken: response.refresh,
      };
      this.setToken(token);
      this._isAutoLoggedIn = true;
      return token;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async login(username: string, password: string): Promise<Token> {
    const response = await this.api.post<AuthResponse>(
      `${environment.api.authUrl}/jwt/token`,
      { username, password },
      { allowAnonymous: true },
    );
    const token = {
      accessToken: response.access,
      refreshToken: response.refresh,
    };
    this.setToken(token);
    await this.afterLogin();
    return token;
  }
  
  async afterLogin(): Promise<void> {
    console.log('afterLogin');
    interface AccountDTO {
      current_customer: number;
      email: string;
      first_name: string;
      last_name: string;
      current_customer_balance: number;
    }
    const dto = await this.api.get<AccountDTO>(urls.accountInfo);
    const account: Account = {
      customerNumber: dto.current_customer,
      email: dto.email,
      firstName: dto.first_name,
      lastName: dto.last_name,
      balance: dto.current_customer_balance,
      customData: _.cloneDeep(emptyAccount.customData),
    };
    const { customerNumber } = account;
    const oldCustomerNumber = getCustomerNumber();
    if (oldCustomerNumber && customerNumber !== oldCustomerNumber) {
      this.clearStorage();
      this.setCustomerNumber(customerNumber);
    }
    try {
      const response = await this.api.get<{ data: any }>(urls.customData.replace(':customerNumber', account.customerNumber.toString()));
      const { templates = [], contacts = [], failedMessages = [] } = JSON.parse(response.data) || {};
      account.customData = { templates, contacts, failedMessages };
      this.cachedCustomData = _.cloneDeep(account.customData);
    } catch (e) {
      // no custom data yet
    }
    this.account = account;
  }

  async logout() {
    try {
      if (this.refreshThread) {
        clearTimeout(this.refreshThread);
        this.refreshThread = null;
      }
      this.clearStorage();
      this.initPromise = null;
    } catch (e) {
      console.error(e);
      // exit silently
    }
  }

  async saveCustomData() {
    if (!this.account.customerNumber || _.isEqual(this.account.customData, this.cachedCustomData)) {
      return;
    }
    await this.api.post(urls.customData.replace(':customerNumber', this.account.customerNumber.toString()), { data: JSON.stringify(this.account.customData) });
    this.cachedCustomData = _.cloneDeep(this.account.customData);
  }

  protected clearStorage() {
    localStorage.removeItem(storageKeys.auth.token);
    localStorage.removeItem(storageKeys.auth.customerNumber);
    do {
      const keyIndex = _.range(0, localStorage.length).find(i => localStorage.key(i)?.startsWith(environment.storagePrefix));
      if (keyIndex === undefined) {
        break;
      }
      localStorage.removeItem(localStorage.key(keyIndex) || '');
    } while (true);
    do {
      const keyIndex = _.range(0, sessionStorage.length).find(i => sessionStorage.key(i)?.startsWith(environment.storagePrefix));
      if (keyIndex === undefined) {
        break;
      }
      sessionStorage.removeItem(sessionStorage.key(keyIndex) || '');
    } while (true);
  }

  protected async refreshTokens(): Promise<Token | null> {
    if (this.refreshThread) {
      clearTimeout(this.refreshThread);
      this.refreshThread = null;
    }
    const currentRefreshToken = getToken()?.refreshToken;
    if (!currentRefreshToken) {
      return anonymousToken;
    }
    let token: Token;
    try {
      const response = await this.api.post<AuthResponse>(
        `${environment.api.authUrl}/jwt/refresh`,
        { refresh: currentRefreshToken },
      );
      token = {
        accessToken: response.access,
        refreshToken: response.refresh,
      };
    } catch (e: any) {
      if (typeof e === 'object' && e.message?.startsWith('401')) {
        await this.logout();
        forceReload();
        return null;
      }
      throw e;
    }
    setStorageItem(storageKeys.auth.token, token);
    this.refreshThread = setTimeout(this.refreshTokens, (tokenExpiration - 0.5) * 60000);
    return token;
  }
}

let authService: AuthService;

export function useAuthService(): AuthService {
  const api = useApiService();
  return authService = authService || new AuthService({ api });
}
