import { environment } from 'environments';
import _ from 'lodash-es';

const soundDefinitions = environment.sounds;

export class AudioService {

  private sounds = _.toPairs(soundDefinitions).map(([name, data]) => {
    const file = typeof data === 'string' ? data : data.file;
    const source =
      file.includes('://') ? file :
      file.startsWith('/sounds/') ? `${environment.baseUrl}${file.substring(1)}` :
      `${environment.baseUrl}/sounds/${file}`;
    const volume = typeof data === 'string' ? 1 : (data.volume || 1);
    const audio = new Audio(source);
    return { name, audio, volume };
  });

  constructor() {
    this.init();
  }

  public async init(): Promise<void> {
    for (const sound of this.sounds) {
      sound.audio.volume = 0;
      try {
        sound.audio.play();
      } catch (error) {}
    }
  }

  public play(name: keyof typeof soundDefinitions, relativeVolume: number = 1): void { // real volume = relativeVolume x sound.volume
    const sound = this.sounds.find(s => s.name === name);
    if (!sound) {
      throw Error(`Cannot play unknown audio ${name}`);
    }
    try {
      sound.audio.pause();
      sound.audio.currentTime = 0;
      sound.audio.volume = sound.volume * relativeVolume;
      sound.audio.play();
    } catch (error) {
      console.warn(`Cannot play audio ${name}`, error);
    }
  }

  stop(name: keyof typeof soundDefinitions) {
    const sound = this.sounds.find(s => s.name === name);
    if (!sound) {
      throw Error(`Cannot stop unknown audio ${name}`);
    }
    sound.audio.pause();
    sound.audio.currentTime = 0;
  }
}

let audioService: AudioService;

export function useAudioService(): AudioService {
  audioService = audioService || new AudioService();
  return audioService;
}
