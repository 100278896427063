import { ApiService, useApiService } from './useApiService';
import { storageKeys } from 'const/storage-keys';
import { getStorageItem, setStorageItem } from 'helpers/storage';
import _ from 'lodash-es';
import { Updates, useManageUpdates } from 'hooks/useManageUpdates';
import { urls } from 'const/urls';
import { AuthService, useAuthService } from './useAuthService';
import { showToast } from 'helpers/toast';
import { ContactService, useContactService } from './useContactService';
import { Coding, MassMessage, SmsCampaign } from 'models/MassMessage';

export class SmsCampaignService {
  private _campaigns: SmsCampaign[] = getStorageItem<SmsCampaign[]>(storageKeys.sms.smsCampaigns) || [];
  private _messages: MassMessage[] = getStorageItem<MassMessage[]>(storageKeys.sms.massMessages) || [];
  private _smsCampaignsUpdated = false;
  private smsCampaignsLoadingCount = 0;

  public readonly ready: Promise<void>;
  public updates = 0; // is incremented by update()
  public isReady = false;

  private readonly api: ApiService;
  private readonly auth: AuthService;
  private readonly contactService: ContactService;

  constructor({ api, auth, contactService }: { api: ApiService, auth: AuthService, contactService: ContactService }) {
    this.api = api;
    this.auth = auth;
    this.contactService = contactService;
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
      console.log(this._campaigns);
      console.log(this._messages);
    }));
  }

  public update() {
    setStorageItem(storageKeys.sms.smsCampaigns, this._campaigns);
    setStorageItem(storageKeys.sms.massMessages, this._messages);
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public get smsCampaignsLoading(): boolean {
    return this.smsCampaignsLoadingCount > 0;
  }

  public async init(): Promise<void> {
    await this.loadSmsCampaigns();
  }

  private async loadSmsCampaigns() {
    this.smsCampaignsLoadingCount++;
    this.update();
    try {
      interface CampaignResponse {
        campaign_id: number;
        timestamp: string; // ISO Date
        label: string;
        body: string;
      }
      const response = await this.api.get<CampaignResponse[]>(urls.smsCampaigns);
      const campaigns: SmsCampaign[] = response.map(x => ({
        id: x.campaign_id,
        at: new Date(x.timestamp).getTime(),
        name: x.label,
        body: x.body
      }));
      interface CampaignRunResponse {
        timestamp: string; // ISO Date
        run_id: number;
        campaign_id: number;
        sms_list_id: number;
        from_numbers: string;
        status: string;
        coding: string;
      }
      const runsResponse = await this.api.get<CampaignRunResponse[]>(urls.smsCampaignRuns);
      const messages: MassMessage[] = runsResponse.map(x => {
        const campaignId = x.campaign_id;
        const smsCampaign = campaigns.find(c => c.id === campaignId);
        return {
          smsCampaignRunId: x.run_id,
          smsCampaignId: campaignId,
          smsListId: x.sms_list_id,
          at: new Date(x.timestamp).getTime(),
          myNumbers: x.from_numbers.split(',').map(n => n.trim()).filter(Boolean),
          status: x.status,
          coding: x.coding as Coding,
          templateName: smsCampaign?.name || '',
          body: smsCampaign?.body || '',
        };
      });
      this._campaigns = campaigns;
      this._messages = messages;
      this._smsCampaignsUpdated = true;
    } finally {
      this.smsCampaignsLoadingCount--;
      this.update();
    }
  }

  public get campaigns(): SmsCampaign[] {
    return this._campaigns;
  }

  // recent messages going first
  public messages(smsListId: number): MassMessage[] {
    return _.sortBy(this._messages.filter(m => m.smsListId === smsListId), (m: MassMessage) => -m.at);
  }

  public get smsCampaignsUpdated(): boolean {
    return this._smsCampaignsUpdated;
  }

  public async createSmsCampaign({ name, body }: { name: string; body: string; }): Promise<number> {
    const campaign: SmsCampaign = {
      id: 0,
      at: new Date().getTime(),
      name,
      body,
    };
    this._campaigns.push(campaign);
    this.smsCampaignsLoadingCount++;
    this.update();
    try {
      const response = await this.api.post<{ campaign_id: number, timestamp: string; }>(urls.createSmsCampaign, {
        customer_number: this.auth.account.customerNumber,
        label: name,
        body,
      });
      campaign.id = response.campaign_id;
      campaign.at = new Date(response.timestamp).getTime();
      return campaign.id;
    } catch (e) {
      showToast({
        severity: 'error',
        summary: 'Cannot create SMS Campaign',
        detail: 'Unexpected error occurred on creating new SMS campaign. Please try again later or contact us for details',
      })
      console.error(e);
      return 0;
    } finally {
      this.smsCampaignsLoadingCount--;
      this.update();
    }
  }

  public async composeNewMessage({ campaignId, smsListId, fromNumbers, coding = Coding.ucs2 }: {
    campaignId: number;
    smsListId: number;
    fromNumbers: string[];
    coding: Coding;    
  }) {
    const campaign = this._campaigns.find(c => c.id === campaignId);
    let message: MassMessage = {
      smsCampaignRunId: 0,
      smsCampaignId: campaignId,
      smsListId,
      myNumbers: [...fromNumbers],
      status: 'pending',
      coding,
      templateName: campaign?.name || '',
      body: campaign?.body || '',
      at: new Date().getTime(),
    };
    this._messages.push(message);
    this.smsCampaignsLoadingCount++;
    this.update();
    try {
      const response = await this.api.post<{ run_id: number, timestamp: string; }>(urls.runSmsCampaign.replace(':campaignId', campaignId.toString()), {
        list_id: message.smsListId,
        from_numbers: message.myNumbers.join(','),
        coding: message.coding,
      });
      message.smsCampaignRunId = response.run_id;
      message.at = new Date(response.timestamp).getTime();
    } catch (e) {
      showToast({
        severity: 'error',
        summary: 'Cannot run SMS Campaign',
        detail: 'Unexpected error occurred on running the SMS campaign. Please try again later or contact us for details',
      })
      console.error(e);
      return '';
    } finally {
      this.smsCampaignsLoadingCount--;
      this.update();
    }
  }
}

let smsCampaignService: SmsCampaignService;
let updates: Updates = new Set();

export function useSmsCampaignService(): SmsCampaignService {
  useManageUpdates(updates);
  const api = useApiService();
  const auth = useAuthService();
  const contactService = useContactService();
  smsCampaignService = smsCampaignService || new SmsCampaignService({ api, auth, contactService });
  return smsCampaignService;
}
