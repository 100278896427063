export const environment = {
  production: false,
  baseUrl: '/',
  api: {
    baseUrl: 'https://api.apeiron.io/v2/',
    authUrl: 'https://api.apeiron.io/v2/auth',
    autoLoginUrl: 'https://dashboard.apeiron.io/auth/jwt/make_token',
    csrf: {
      cookie: 'csrftoken',
      header: 'X-CSRFToken',
    },
  },
  storagePrefix: 'web-phone-', // for localStorage & sessionStorage keys
  sip: {
    transport: 'wss',
    server: 'webrtc.apeironsys.com',
    port: 9998,
    userAgent: 'Apeiron Communicator - Web v2.0.0',
  },
  sms: {
    webSocket: 'wss://ws.apeiron.io/sms?token={ACCESS_TOKEN}',
    storageLimitDays: 30, // cache only messages for last month
  },
  sounds: {
    answered: `${process.env.PUBLIC_URL}/sounds/phone-pick-up-3.mp3`,
    rejected: `${process.env.PUBLIC_URL}/sounds/phone-pick-up-3.mp3`,
    ringback: `${process.env.PUBLIC_URL}/sounds/phone-pick-up-3.mp3`,
    ringing:  { file: `${process.env.PUBLIC_URL}/sounds/phone-pick-up-3.mp3`, volume: 0.5 },
    incomingSms: `${process.env.PUBLIC_URL}/sounds/msg-notification.wav`,
  },
};
