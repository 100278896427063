import { Contact } from 'models/Contact';
import { Id } from 'models/common';
import { Message, MessageType } from 'models/Message';
import { formatPhoneNumber, toDigitalNumber } from 'helpers/phone';
import { storageKeys } from 'const/storage-keys';
import { getStorageItem, setStorageItem } from 'helpers/storage';
import _ from 'lodash-es';
import { Updates, useManageUpdates } from 'hooks/useManageUpdates';
import { AuthService, useAuthService } from './useAuthService';

export class ContactService {

  private _contacts: Contact[] = getStorageItem<Contact[]>(storageKeys.contacts) || [];
  private _current: Contact | undefined; // must be one of this.contacts or undefined

  public readonly ready: Promise<void>;
  public updates = 0; // is incremented by update()
  public isReady = false;

  // WARNING: don't forget to care about data index integrity!
  private contactIndexById: Record<string, Contact> = _.fromPairs(this.contacts.map(c => [c.id, c]));
  private contactIndexByNumber: Record<string, Contact> = _.fromPairs(_.flatten(this.contacts.map(c => c.numbers.map(n => [n, c]))));
  
  private readonly authService: AuthService;

  constructor({ authService }: { authService: AuthService }) {
    this.authService = authService;
    this.ready = this.init().then((async () => {
      await authService.ready;
      if (authService.account.customData.contacts?.length > 0) {
        // this._contacts = authService.account.customData.contacts;
      }
      this.update();
      this.isReady = true;
    }));
  }

  public update() {
    setStorageItem(storageKeys.contacts, this.contacts);
    this.authService.account.customData.contacts = this.contacts;
    this.authService.saveCustomData();
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
  }

  public get contacts(): Contact[] {
    return this._contacts;
  }

  public get current(): Contact | undefined {
    return this._current;
  }
  public set current(value: Contact | undefined) {
    this._current = this._contacts.find(c => c.id === value?.id);
    this.update();
  }

  public getContactById(contactId: Id): Contact | undefined {
    return this.contactIndexById[contactId];
  }

  public getContactByNumber(number: string): Contact | undefined {
    return this.contactIndexByNumber[number];
  }

  public addNewContact(number: string, lastMessage?: Message): Contact {
    number = toDigitalNumber(number);
    const existingContact = this.contacts.find(c => c.numbers.includes(number));
    if (existingContact && lastMessage &&
      (!existingContact.lastMessage || existingContact.lastMessage.at < lastMessage.at)
    ) {
      existingContact.lastMessage = lastMessage;
      if (lastMessage.type === MessageType.sms) {
        existingContact.lastSmsMessage = lastMessage;
        existingContact.hasSms = true;
      }
      if (lastMessage.type === MessageType.mms) {
        existingContact.lastMmsMessage = lastMessage;
        existingContact.hasMms = true;
      }
      existingContact.hasCalls = existingContact.hasCalls || lastMessage.type === MessageType.call;
      existingContact.hasVoicemails = existingContact.hasVoicemails || lastMessage.type === MessageType.voicemail;
      this.update();
      return existingContact;
    }
    if (existingContact && !lastMessage) {
      return existingContact;
    }
    const contact: Contact = {
      id: number,
      name: formatPhoneNumber(number),
      numbers: [number],
      currentNumber: number,
      lastMessage,
      lastSmsMessage: lastMessage?.type === MessageType.sms ? lastMessage : undefined,
      hasCalls: false,
      hasSms: false,
      hasMms: false,
      hasVoicemails: false,
    };
    this.contacts.push(contact);
    this.contactIndexById[contact.id] = contact;
    for (const contactNumber of contact.numbers) {
      this.contactIndexByNumber[contactNumber] = contact;
    }
    this.update();
    return contact;
  }

  public setCurrentNumber({ contact = this.current, currentNumber }: { contact?: Contact, currentNumber?: string }) {
    if (!contact) {
      return;
    }
    currentNumber = currentNumber || contact.currentNumber || contact.numbers[0];
    if (!contact.numbers.includes(currentNumber)) {
      currentNumber = contact.numbers[0];
    }
    contact.currentNumber = currentNumber;
  }

  public setName(name: string) {
    if (!this.current) {
      return;
    }
    const newName = (name || '').trim() || formatPhoneNumber(this.current.numbers[0]);
    if (newName === this.current.name) {
      return;
    }
    this.current.name = newName;
    this.update();
  }
}

let contactService: ContactService;
let updates: Updates = new Set();

export function useContactService(): ContactService {
  useManageUpdates(updates);
  const authService = useAuthService();
  contactService = contactService || new ContactService({ authService });
  return contactService;
}
