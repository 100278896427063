import { RefObject } from 'react';
import { Feature, MyNumber } from 'models/MyNumber';
import { MyNumberService, useMyNumberService } from './useMyNumberService';
import { WebSocketInterface, UA, debug as sipDebug } from "jssip";
import { RTCSessionEvent } from "jssip/lib/UA";
import { RTCSession, RTCSessionEventMap, EndEvent, IncomingEvent, OutgoingEvent } from 'jssip/lib/RTCSession';
import { CallOptions } from "jssip/lib/UA";
import { environment } from 'environments';
import { CallState, Direction, Message, MessageType } from 'models/Message';
import { AudioService, useAudioService } from './useAudioService';
import { storageKeys } from 'const/storage-keys';
import { ContactService, useContactService } from './useContactService';
import { getStorageItem, setStorageItem } from 'helpers/storage';
import { Id } from 'models/common';
import { delay } from 'helpers/delay';
import { Updates, useManageUpdates } from 'hooks/useManageUpdates';
import { showToast } from 'helpers/toast';
import { generateGuid } from 'helpers/guid';
import _ from 'lodash-es';

sipDebug.enable('JsSIP:Transport JsSIP:RTCSession*');

export class CallService {

  private callHistory: Record<Id, Message[]> = getStorageItem(storageKeys.calls.messages) || {}; // key is contactId; messages are sorted `at desc`
  private _currentCall: Message | undefined;
  private _currentSession: RTCSession | undefined;
  private _remoteAudio: HTMLAudioElement | undefined;
  private _incomingCallRinging = false;
  private _outgoingCallRinging = false;

  public readonly ready: Promise<void>;
  public isReady = false;
  public updates = 0; // is incremented by update()

  private readonly audio: AudioService;
  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;

  constructor({ audio, myNumberService, contactService }: {audio: AudioService, myNumberService: MyNumberService, contactService: ContactService }) {
    this.audio = audio;
    this.myNumberService = myNumberService;
    this.contactService = contactService;
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    setStorageItem(storageKeys.calls.messages, this.callHistory);
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
    if (this.currentCall) {
      this.contactService.update(); // update last message of the contact by current call
    }
  }

  public async init(): Promise<void> {
    await Promise.all([this.myNumberService.ready, this.contactService.ready]);
    const myCallsNumbers = this.myNumberService.myNumbers.filter(n => n.features.includes(Feature.calls));
    for (const myNumber of myCallsNumbers) {
      this.establishSipConnection(myNumber);
    }
    // wait until numbers are registered
    while (myCallsNumbers.some(n => !n.calls!.ready)) {
      await delay(50);
    }
  }

  public getMessagesByContactId(contactId: Id): Message[] {
    return this.callHistory[contactId] || [];
  }

  public get currentCall(): Message | undefined {
    return this._currentCall;
  }

  public get muted(): boolean {
    return this.currentSession?.isMuted()?.audio || false
  }
  public set muted(value: boolean) {
    if (!this.currentSession) {
      return;
    }
    if (value) {
      this.currentSession.mute();
    } else {
      this.currentSession.unmute();
    }
  }

  public get onHold(): boolean {
    return this.currentSession?.isOnHold()?.local || false
  }
  public set onHold(value: boolean) {
    if (!this.currentSession) {
      return;
    }
    if (value) {
      this.currentSession.hold();
    } else {
      this.currentSession.unhold();
    }
  }

  protected get currentSession(): RTCSession | undefined {
    return this._currentSession;
  }
  protected set currentSession(session: RTCSession | undefined) {
    this._currentSession = session;
    if (!session) {
      if (this._remoteAudio) {
        this._remoteAudio.pause();
        this._remoteAudio.srcObject = null;
      }
      return;
    }
    session.connection?.addEventListener('addstream', (event: any) => {
      this.addTracksToStream(event.stream);
    });
  }

  protected get incomingCallRinging(): boolean {
    return this._incomingCallRinging;
  }
  protected set incomingCallRinging(value: boolean) {
    this._incomingCallRinging = value;
    if (value) {
      this.audio.play('ringing');
    } else {
      this.audio.stop('ringing');
    }
  }

  protected get outgoingCallRinging(): boolean {
    return this._outgoingCallRinging;
  }
  protected set outgoingCallRinging(value: boolean) {
    this._outgoingCallRinging = value;
    if (value) {
      this.audio.play('ringback');
    } else {
      this.audio.stop('ringback');
    }
  }

  private establishSipConnection = (myNumber: MyNumber) => {
    const { transport, server, port, userAgent } = environment.sip;

    const socket = new WebSocketInterface(`${transport}://${server}:${port}`);
    socket.via_transport = transport;
  
    const ua = new UA({
      sockets: [socket],
      uri: `sip:${myNumber.number}@${server}`,
      password: myNumber.calls!.sipPassword,
      authorization_user: myNumber.number,
      display_name: myNumber.number,
      user_agent: userAgent,
    });
    myNumber.calls!.sipAgent = ua;
    ua.registrator().setExtraHeaders([`Origin: https://${server}`]);
    ua.on('registered', this.onSipRegistrationChange(myNumber, true));
    ua.on('unregistered', this.onSipRegistrationChange(myNumber, false));
    ua.on('connected', () => console.log(`SIP ${myNumber.name} connected`));
    ua.on('disconnected', () => console.log(`SIP ${myNumber.name} disconnected`));
    ua.on('newRTCSession', this.onNewRTCSession(myNumber));
    ua.start();
  
    return ua;
  };

  private onSipRegistrationChange(myNumber: MyNumber, ready: boolean): () => void {
    return () => {
      console.log(`SIP ${myNumber.name} ${ready ? 'registered' : 'unregistered'}`);
      myNumber.calls!.ready = ready;
      this.update();
      this.myNumberService.update();
    };
  }
  
  private onNewRTCSession(myNumber: MyNumber): (data: RTCSessionEvent) => void {
    return (data: RTCSessionEvent) => {
      const direction = data.originator === 'remote' ? Direction.in : Direction.out;
      if (direction === Direction.out) {
        return;
      }

      // if busy or other incoming then reply with 486 "Busy Here"
      if (this.currentSession) {
        data.session.terminate({ status_code: 486, reason_phrase: "Busy Here" });
        return;
      }

      const { session } = data;
      this.currentSession = session;
      this.incomingCallRinging = true;

      this.createCurrentCall({ myNumber, contactNumber: session.remote_identity.uri.user, direction });

      const logSessionEvents = <T extends keyof RTCSessionEventMap>(eventNames: T[]) => {
        for (const eventName of eventNames) {
          session.on(eventName, (...args: any[]) => console.log(`SIP ${myNumber.name} event ${eventName}`, ...args));
        }
      }
      logSessionEvents([
        'update',
        'connecting',
        'sdp',
        'sending',
        'peerconnection:setremotedescriptionfailed',
        'peerconnection:setlocaldescriptionfailed',
        'peerconnection:createanswerfailed',
        'peerconnection:createofferfailed',
      ]);

      session.on('accepted', (data: IncomingEvent | OutgoingEvent) => {
        console.log(`SIP ${myNumber.name} event accepted`, data);
        this.incomingCallRinging = false;
        this.addTracksToStream();
        const call = this.currentCall!;
        call.callState = CallState.ongoing;
        call.callStartedAt = call.at = new Date().getTime();
      }).on('ended', (data: EndEvent) => {
        console.log(`SIP ${myNumber.name} event ended`, data);
        this.onCallEnd(data);
      }).on('failed', (data: EndEvent) => {
        console.log(`SIP ${myNumber.name} event failed`, data);
        this.onCallEnd(data);
      });
      console.log(session);
      
    };

  }

  private startIceTimer(session: RTCSession | undefined) {

    try {

      let ready:any = null;
      
      setTimeout(function() {

        try {

          if(ready) {
            console.log('We got Ice timeout here so calling now');
            ready();
          } 
          
        } catch(e) {
          console.error(e);
        }

      }, 5000);
      
      session?.on('icecandidate', (data: any) => {
        ready = data.ready;
      });

      session?.on('sdp', (data: any) => {
        ready = null;
      });


    } catch(e) {
      console.error(e);
    }
  }

  public async dial(contactNumber: string) {
    const myNumber = this.myNumberService.current;
    if (!myNumber?.features.includes(Feature.calls)) {
      if (myNumber) {
        showToast({
          severity: 'warn',
          summary: 'Disabled feature',
          detail: `Sorry, unfortunately Calls are disabled for ${myNumber.name}.`,
        });
      } else {
        showToast({
          severity: 'warn',
          summary: 'Unknown source number',
          detail: `Please select one of your phone numbers which should be used for the call.`,
        });
      }
      return;
    }
    if (!myNumber.calls?.ready || !myNumber.calls.sipAgent) {
      showToast({
        severity: 'warn',
        summary: `Source number is not registered`,
        detail: `Your phone number ${myNumber.name} is not ready yet for calling. Please try again later. If the error persist, please contact us.`,
      });
      return;
    }
    this.createCurrentCall({ myNumber, contactNumber, direction: Direction.out });

    const eventHandlers: CallOptions['eventHandlers'] = {
      progress: (event: IncomingEvent) => {
        console.log('Call progressing', event, session);
        this.outgoingCallRinging = true;
        const call = this.currentCall!;
        call.callState = CallState.ringing;
        call.sentAt = call.at = new Date().getTime();
        this.update();
      },
      confirmed: (event: IncomingEvent) => {
        console.log('Call confirmed', event, session);
        this.outgoingCallRinging = false;
        this.audio.play('answered');
        const call = this.currentCall!;
        call.callState = CallState.ongoing;
        call.callStartedAt = call.at = new Date().getTime();
        this.update();
      },
      failed: (data: EndEvent) => {
        console.log('Call failed', data, session);
        this.audio.play('rejected');
        const call = this.currentCall!;
        this.onCallEnd(data);
        showToast({
          severity: 'warn',
          summary: `Call failed`,
          detail: `The call has been ${call.callState}.`,
        });
        this.update();
      },
      ended: (data: EndEvent) => {
        console.log('Call ended', data, session);
        this.onCallEnd(data);
      },
    };
  
    const session = myNumber.calls.sipAgent.call(contactNumber, {
      pcConfig: { iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }] },
      eventHandlers,
      mediaConstraints: { audio: true, video: false },
      rtcAnswerConstraints: { offerToReceiveAudio: true, offerToReceiveVideo: false },
      rtcOfferConstraints: { offerToReceiveAudio: true, offerToReceiveVideo: false },
    });
  
    this.currentSession = session;
    this.startIceTimer(session);
  };

  public hangUp() {
    if (!this.currentSession) {
      return;
    }
    this.currentSession.terminate({ cause: 'Terminated', reason_phrase: 'normal_disconnect' });
  }

  // don't use it directly (other that inside useCallService)
  public setRemoteAudio(remoteAudio: HTMLAudioElement | undefined) {
    this._remoteAudio = remoteAudio;
  }

  private createCurrentCall({ myNumber, contactNumber, direction }: { myNumber: MyNumber; contactNumber: string; direction: Direction }) {
    const now = new Date().getTime();
    const currentCall: Message = {
      myNumber: myNumber.number,
      contactNumber,
      direction,
      type: MessageType.call,
      entityId: generateGuid(),
      at: now, // later will be overriden by startedAt, and finally by endedAt
      sentAt: now,
      callState: direction === Direction.out ? CallState.connecting : CallState.ringing,
    }
    const contact =
    this.contactService.getContactByNumber(contactNumber) ||
    this.contactService.addNewContact(contactNumber, currentCall);
    this.contactService.current = contact;
    contact.hasCalls = true;
    contact.lastMessage = currentCall;
    this.callHistory[contact.id] = this.callHistory[contact.id] || [];
    this.callHistory[contact.id].unshift(currentCall);
    this._currentCall = currentCall;
    this.update();
  }

  private onCallEnd(data: EndEvent) {
    console.log('Call ended, reason:', data.cause);
    this.incomingCallRinging = false;
    this.outgoingCallRinging = false;
    const call = this.currentCall;
    if (!call) {
      return;
    }
    call.at = new Date().getTime(); // ended at
    call.callStartedAt = call.callStartedAt || call.at;
    const getNextCallState = () => {
      if (call.direction === Direction.in) {
        switch (call.callState) {
          case CallState.ringing: return data.cause === 'Cancelled' || data.cause === 'Terminated' ? CallState.cancelled : CallState.missed;
          case CallState.ongoing: return CallState.ended;
        }
      } else {
        switch (call.callState) {
          case CallState.connecting: return CallState.cancelled;
          case CallState.ringing: return data.cause === 'Rejected' ? CallState.rejected : CallState.noAnswer;
          case CallState.ongoing: return CallState.ended;
        }
      }
    }
    call.callState = getNextCallState();
    this._currentCall = this.currentSession = undefined;
    this.update();
  }

  private addTracksToStream = (stream?: MediaStream) => {
    const session = this.currentSession;
    if (!session) {
      return;
    }
    const remoteTrackList = session.connection.getReceivers();
    // const remoteStream = session.connection.getRemoteStreams()[0];

    const updatedStream = new MediaStream(); // remoteStream.clone();
    remoteTrackList.map(rtpReceiver => updatedStream.addTrack(rtpReceiver.track));
    if (this._remoteAudio) {
      this._remoteAudio.srcObject = updatedStream; // remoteTrack[0];
      this._remoteAudio.play();
    }
  };

  public sendDTMF(digit: string) {
    if (!this._currentSession) {
      return;
    }
    const dtmfSender = this._currentSession.connection.getSenders()[0].dtmf;
    if (!dtmfSender) {
      return;
    }
    dtmfSender.insertDTMF(digit);
  }

  public canSendDTMF(): boolean {
    if (!this._currentSession) {
      return false;
    }
    const dtmfSender = this._currentSession.connection.getSenders()[0].dtmf;
    return dtmfSender?.canInsertDTMF || false;
  }

  get totalMessageCount(): number {
    return _.flatten(Object.values(this.callHistory)).length;
  }
}

let callService: CallService;
let updates: Updates = new Set();

export function useCallService({ remoteAudioRef }: { remoteAudioRef?: RefObject<HTMLAudioElement> } = {}): CallService {
  useManageUpdates(updates);
  const audio = useAudioService();
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  callService = callService || new CallService({ audio, myNumberService, contactService });
  if (remoteAudioRef) {
    callService.setRemoteAudio(remoteAudioRef.current || undefined);
  }
  return callService;
}
