import { UA } from 'jssip';
import { PhoneNumber } from './common';
import { AppMode } from './navigation';

export enum Feature {
  calls = 'calls',
  sms = 'sms',
  mms = 'mms',
  voicemails = 'voicemails',
}

export const features = [Feature.calls, Feature.sms, Feature.mms, Feature.voicemails];

export const featureInfo: Record<Feature, { name: string; mode: AppMode }> = {
  [Feature.calls]: { name: 'Calls', mode: AppMode.calls },
  [Feature.sms]: { name: 'SMS', mode: AppMode.sms },
  [Feature.mms]: { name: 'MMS', mode: AppMode.sms },
  [Feature.voicemails]: { name: 'Voicemails', mode: AppMode.voicemails },
};


// key is number
export interface MyNumber {
  features: Feature[];
  number: PhoneNumber;
  name: string; // = formatPhoneNumber(number)
  calls?: { // exists when features.includes(Feature.calls)
    ready: boolean; // true when SIP is initialized for calls
    sipPassword: string;
    sipAgent?: UA;
  },
  sms?: { // exists when features.includes(Feature.sms)
    ready: boolean; // true when web socket is initialized for messaging
  }
  mms?: { // exists when features.includes(Feature.mms)
    ready: boolean; // true when web socket is initialized for messaging
  }
}
