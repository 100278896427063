import React, { useEffect, useRef, useState } from 'react';
import { Loader } from 'components/Loader/Loader';
import { useCallService } from 'hooks/services/useCallService';
import { useChatService } from 'hooks/services/useChatService';
import { useContactService } from 'hooks/services/useContactService';
import { useNavigationService } from 'hooks/services/useNavigationService';
import { useVoicemailService } from 'hooks/services/useVoicemailService';
import { Message, MessageType } from 'models/Message';
import { AppMode } from 'models/navigation';
import { ChatMessage } from './ChatMessage';
import { ComposeSms } from 'pages/sms/Compose/ComposeSms';
import "./Messages.scss";
import { InputText } from 'primereact/inputtext';
import { formatPhoneNumber, toDigitalNumber } from 'helpers/phone';
import { usePrevious } from 'hooks/usePrevious';

export const Messages = () => {

  const { mode } = useNavigationService();
  const { current: contact } = useContactService();
  const chatService = useChatService();
  const callService = useCallService();
  const voicemailService = useVoicemailService();
  const contactService = useContactService();

  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [newContactNumber, setNewContactNumber] = useState('');

  const prevContactId = usePrevious(contact?.id);
  const prevMode = usePrevious(mode);
  const softLock = useRef<number>(0);
  const totalMessageCount = chatService.totalMessageCount;
  const prevTotalMessageCount = usePrevious(totalMessageCount);
  useEffect(() => {
    if (contact?.id === prevContactId && mode === prevMode && (totalMessageCount === prevTotalMessageCount || loading)) {
      return;
    }
    softLock.current++;
    (async () => {
      const contactId = contact?.id;
      const currentVersion = softLock.current;
      if (!contactId) {
        return;
      }
      if (loading && contact.id === prevContactId) {
        return;
      }
      switch (mode) {
        case AppMode.calls: setMessages(callService.getMessagesByContactId(contactId)); break;
        case AppMode.voicemails: setMessages(voicemailService.getMessagesByContactId(contactId)); break;
        default: {
          setLoading(true);
          setMessages([]);
          let messages = await chatService.getMessagesByContactId(contactId);
          if (mode === AppMode.sms) {
            messages = messages.filter(m => [MessageType.sms, MessageType.mms].includes(m.type));
          }
          if (currentVersion === softLock.current) {
            setMessages(messages);
            setLoading(false);
          }
          break;
        }
      }
    })();
  }, [
    contact, mode, totalMessageCount,
    prevContactId, prevMode, prevTotalMessageCount,
    chatService, chatService.updates, callService, voicemailService, loading,
  ]);

  const inputWrapper = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      const inputElement = inputWrapper.current?.querySelector('input') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }, 100);
  }, [contact?.id]);

  const onNewContactNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const digital = toDigitalNumber(e.target.value);
    const existedContact = contactService.contacts.find(c => c.numbers.includes(digital));
    if (existedContact) {
      contactService.setCurrentNumber({ contact: existedContact, currentNumber: digital });
      contactService.current = existedContact;
      setNewContactNumber('');
      return;
    }
    setNewContactNumber(digital);
  }

  return (
    <div className="messages-component auto-flex">
      {contact || ![AppMode.chats, AppMode.sms].includes(mode) ?
        <div className="messages auto-flex">
          <ul>
            {loading && <li><Loader /></li>}
            {messages.map(msg => <ChatMessage key={`${msg.type}#${msg.entityId}`} message={msg} />)}
          </ul>
        </div>
      :
        <div className="new-number-area">
          <h3>New contact</h3>
          <p>Please enter the phone number of existing or new contact, and type the message below.</p>
          <p>If entered number already exists in your contact list, you'll be redirected the contact as soon as you enter the number.</p>
          <InputText
            value={formatPhoneNumber(newContactNumber)}
            onChange={onNewContactNumberChange}
            placeholder="Phone number"
            title="Phone number"
          />
        </div>
      }
      {[AppMode.chats, AppMode.sms].includes(mode) ?
        <ComposeSms newPhoneNumber={newContactNumber} />
      : null}
    </div>
  );

}
