import _ from 'lodash-es';
import { storageKeys } from 'const/storage-keys';
import { ApiService, useApiService } from './useApiService';
import { getStorageItem, setStorageItem } from 'helpers/storage';
import { urls } from 'const/urls';
import { Id } from 'models/common';
import { getCustomerNumber } from 'helpers/token';
import { Media } from 'models/Media';
import { Updates, useManageUpdates } from 'hooks/useManageUpdates';

export class MediaService {

  public media: Media[] = getStorageItem(storageKeys.media) || [];

  // WARNING: don't forget to care about data index integrity!
  private mediaById: Record<string, Media> = _.fromPairs(this.media.map(m => [m.id, m]));
  private mediaByUrl: Record<string, Media> = _.fromPairs(this.media.map(m => [m.url, m]));

  public readonly ready: Promise<void>;
  public isReady = false;
  public updates = 0; // is incremented by update()

  private readonly api: ApiService;

  constructor({ api }: { api: ApiService }) {
    this.api = api;
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    setStorageItem(storageKeys.media, this.media);
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
    await this.loadMedia();
  }

  private async loadMedia(): Promise<void> {
    interface DocumentsResponse {
      count: number;
      next?: string;
      results: Array<{
        id: Id;
        timestamp: string; // WARNING: that's not seconds (as in other endpoints)! instead, it's date.toISOString()
        content_type: string;
        url: string;
      }>;
    }
    const firstPageUrl = urls.mediaDocuments.replace(':customerNumber', getCustomerNumber().toString());
    let url = firstPageUrl;
    do {
      const response = await this.api.get<DocumentsResponse>(url);
      if (url === firstPageUrl) { // page 1
        if (response.count === getStorageItem<number>(storageKeys.documentCount)) {
          break;
        }
        setStorageItem(storageKeys.documentCount, response.count || 0);
      }
      for (const x of response.results.filter(doc => !this.mediaById[doc.id] && this.mediaByUrl[doc.url])) {
        const media = this.mediaByUrl[x.url];
        media.id = x.id;
      }
      const newMedia: Media[] = response.results.filter(doc =>
        !this.mediaByUrl[doc.url] &&
        (doc.content_type.startsWith('audio/') || doc.content_type.startsWith('video/'))
      ).map(x => ({
        id: x.id,
        url: x.url,
        mimeType: x.content_type,
      } as Media));
      for (const media of newMedia) {
        this.addNewMedia(media);
      }
      url = response.next || '';
    } while (url);
  }

  addNewMedia(media: Media): Media {
    this.media.push(media);
    if (media.id) {
      this.mediaById[media.id] = media;
    }
    this.update();
    return media;
  }

  public getMediaById(id: Id): Media | undefined {
    return this.mediaById[id];
  }

  public getMediaByUrl(url: string): Media | undefined {
    return this.mediaByUrl[url];
  }
}

let mediaService: MediaService;
let updates: Updates = new Set();

export function useMediaService(): MediaService {
  useManageUpdates(updates);
  const api = useApiService();
  mediaService = mediaService || new MediaService({ api });
  return mediaService;
}
