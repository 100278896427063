import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigationService } from "hooks/services/useNavigationService";
import { TabPanel, TabView } from "primereact/tabview";
import { pagesByMode, pageInfo, Page, AppMode } from "models/navigation";
import { useCallService } from "hooks/services/useCallService";
import "./PageTabs.sass";
import { usePrevious } from "hooks/usePrevious";

export const PageTabs = () => {

  const callService = useCallService();
  const navigation = useNavigationService();
  
  const { mode, page } = navigation;
  const hasCall = Boolean(callService.currentCall);

  const [modeBeforeCall, setModeBeforeCall] = useState<AppMode | undefined>();
  const [pageBeforeCall, setPageBeforeCall] = useState<Page | undefined>();

  const prevHasCall = usePrevious(hasCall);
  
  useEffect(() => {
    if (hasCall === prevHasCall) {
      return;
    }
    if (hasCall) {
      const prevMode = navigation.mode;
      const prevPage = navigation.page;
      navigation.mode = AppMode.calls;
      navigation.page = Page.activeCall;
      setModeBeforeCall(prevMode);
      setPageBeforeCall(prevPage);
    } else {
      navigation.page = Page.callHistory;
      if (modeBeforeCall) {
        navigation.mode = modeBeforeCall;
      }
      if (pageBeforeCall) {
        navigation.page = pageBeforeCall;
      }
      setModeBeforeCall(undefined);
      setPageBeforeCall(undefined);
    }
  }, [prevHasCall, hasCall, navigation, modeBeforeCall, pageBeforeCall]);

  const pages = useMemo(() => {
    if (mode === AppMode.calls) {
      return pagesByMode[mode].filter(p => hasCall || p !== Page.activeCall);
    }
    return pagesByMode[mode];
  }, [mode, hasCall]);
  const pageIndex = pages.indexOf(page);

  const setPageIndex = useCallback(({ index }: { index: number }) => {
    const nextPage = pages[index];
    navigation.page = nextPage;
  }, [navigation, pages]);

  if (pages.length < 2) {
    return null;
  }

  return (
    <div className="page-tabs-component">
      <TabView activeIndex={pageIndex} onTabChange={setPageIndex}>
        {pages.map(p => <TabPanel key={p} header={pageInfo[p].name} />)}
      </TabView>
    </div>
  );
}
