import { getTextDuration } from "helpers/date";
import { DateTime, Id, PhoneNumber } from "./common";

export enum Direction {
  in = 'inbound',
  out = 'outbound',
}

export enum MessageType {
  call = 'Call',
  sms = 'SMS',
  mms = 'MMS',
  voicemail = 'Voicemail',
}

export enum CallState {
  connecting = 'connecting', // only for outgoing
  ringing = 'ringing',
  ongoing = 'ongoing', // answered
  ended = 'ended', // successfully
  cancelled = 'cancelled', // ended during connecting
  noAnswer = 'noAnswer', // ended during outgoing ringing
  rejected = 'rejected',
  missed = 'missed',
  failed = 'failed', // unknown reason
};

// key is { type, entityId }
export interface Message {
  myNumber: PhoneNumber;
  contactNumber: PhoneNumber;
  direction: Direction;
  type: MessageType;
  entityId?: Id;
  at: DateTime; // for call it means "ended at"
  sentAt?: DateTime; // for call it means "initiated at"
  sending?: boolean;
  failed?: string | boolean;
  // viewedAt?: DateTime;
  body?: string; // text of the message
  // launchedAt?: DateTime;
  mediaId?: Id; // for mms, voicemail
  callStartedAt?: DateTime; // required for call after ringing; for ongoing, cancelled, rejected calls - it equals to `at` (that is actually "ended at")
  callState?: CallState; // required for call;
}

export const getMessageDescription = (message: Message): string => {
  switch (message.type) {
    case MessageType.call: {
      const callDuration = message.at - (message.callStartedAt || message.at);
      const callDurationSuffix = callDuration ? ` (${getTextDuration(callDuration)})` : '';
      const ringingDuration = (message.callStartedAt || message.at) - message.sentAt!;
      const ringingDurationSuffix = ringingDuration ? ` (${getTextDuration(ringingDuration)})` : '';
      const dir = message.direction === Direction.in ? 'Incoming' : 'Outgoing';
      switch (message.callState) {
        case CallState.connecting: return 'Connecting...';
        case CallState.ringing: return 'Calling...';
        case CallState.cancelled: return 'Cancelled Call';
        case CallState.noAnswer: return `No Asnwer ${ringingDurationSuffix}`;
        case CallState.rejected: return `Rejected Call`;
        case CallState.missed: return `Missed Call ${ringingDurationSuffix}`;
        case CallState.failed: return `Failed Call`;
        default: return `${dir} Call${callDurationSuffix}`;
      }
    }
    case MessageType.sms: return message.body || '';
    case MessageType.mms: return message.body || 'MMS message';
    case MessageType.voicemail: return 'Voicemail';
    default: return '';
  }
};
