import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCallService } from 'hooks/services/useCallService';
import { useContactService } from 'hooks/services/useContactService';
import { useNavigationService } from 'hooks/services/useNavigationService';
import { useSmsService } from 'hooks/services/useSmsService';
import { AppMode } from 'models/navigation';
import { useMyNumberService } from 'hooks/services/useMyNumberService';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { routes } from 'const/routes';
import { AutoComplete, AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { useTemplateService } from 'hooks/services/useTemplatesService';
import { Template } from 'models/Template';
import { InputTextarea } from 'primereact/inputtextarea';
import { Feature } from 'models/MyNumber';
import { Card } from 'primereact/card';
import { getFileContent } from 'helpers/file';
import { useMediaService } from 'hooks/services/useMediaService';
import { generateGuid } from 'helpers/guid';
import "./ComposeSms.scss";
import { Message } from 'models/Message';
import { Media } from 'models/Media';
import { BsPaperclip, BsTelephone } from 'react-icons/bs';
import { MdOutlineClose, MdSend } from 'react-icons/md';

export const ComposeSms = ({ newPhoneNumber = '' }: { newPhoneNumber?: string } = {}) => {
  
  const { mode } = useNavigationService();
  const { current: contact } = useContactService();
  const callService = useCallService();
  const smsService = useSmsService();
  const mediaService = useMediaService();
  const myNumberService = useMyNumberService();
  const templateService = useTemplateService();

  const [template, setTemplate] = useState<Template>();
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>();
  const [hasMmsFeature, setHasMmsFeature] = useState(myNumberService.hasFeature(Feature.mms));
  const [file, setFile] = useState<File | undefined>(undefined);

  const draft: Message | undefined = smsService.getDraftMessage(contact?.id);
  const media: Media | undefined = (draft?.mediaId && mediaService.getMediaById(draft.mediaId)) || undefined;

  const hasDraft = Boolean(draft);
  useEffect(() => {
    if (!hasDraft) {
      smsService.createDraftMessage(contact?.id, newPhoneNumber);
    }
  }, [hasDraft, smsService, contact?.id, newPhoneNumber]);

  useEffect(() => {
    if (newPhoneNumber) {
      smsService.setNewDraftContactNumber(newPhoneNumber);
    }
  }, [newPhoneNumber, smsService])

  const sendSms = async () => {
    if (!contact || !draft) {
      return;
    }
    while (media && !media.fileContent) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    return smsService.sendDraftMessage(contact.id);
  }

  const call = () => {
    const number = contact?.currentNumber || newPhoneNumber;
    if (number) {
      callService.dial(number);
    }
  }

  const inputWrapper = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      const inputElement = inputWrapper.current?.querySelector('input') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }, 100);
  }, [contact?.id]);

  const setMessageText = useCallback(async (text: string) => {
    while (!draft) {
      await new Promise(resolve => setTimeout(resolve, 40));
    }
    if (draft.body === text) {
      return;
    }
    draft.body = text;
    smsService.update();

    // filter by search pattern
    let rx: RegExp | null = null;
    try {
      rx = new RegExp(`(^|\\b)${text}`, 'i');
    } catch {
      // ignore bad rx
    }
    setFilteredTemplates(templateService.templates.filter(t => !rx || rx.test(t.name || '')));
  }, [draft, smsService, templateService.templates]);

  const onTextChange = (e: AutoCompleteCompleteMethodParams) => {
    setMessageText(e.query);
  }

  const onFileUploadChange = (event: any) => {
    if (!draft || !hasMmsFeature || !contact?.currentNumber) {
      return;
    }
    if (!smsService.updateDraftForMms(contact?.id)) {
      setHasMmsFeature(false);
      return;
    }
    console.log(event);
    const theFile: File = event.target.files[0];
    if (file === theFile) {
      return;
    }
    if (!theFile) {
      resetFile();
      return;
    }
    draft.mediaId = mediaService.addNewMedia({
      id: generateGuid(),
      mimeType: theFile.type,
      size: theFile.size,
    }).id;
    smsService.update();
  }

  const formatFileSize = (size: number) => {
    const units = ['b', 'kb', 'Mb', 'Gb', 'Tb'];
    for (let unitPower = units.length - 1; unitPower >= 0; unitPower--) {
      if (size >= 1024 ** unitPower) {
        return `${Math.round(size / (1024 ** unitPower) * 10) / 10}${units[unitPower]}`
      }
    }
  }

  const resetFile = useCallback(() => {
    if (draft?.mediaId || file) {
      if (draft) {
        draft.mediaId = undefined;
      }
      if (file) {
        setFile(undefined);
      }
      smsService.update();
    }
  }, [draft, file, smsService]);

  useEffect(() => {
    if (template) {
      setMessageText(template.body);
      resetFile();
    }
  }, [template, setMessageText, resetFile]);

  useEffect(() => {
    if (media && file && !media.downloadingFileContent && !media.fileContent) {
      const theFile = file;
      media.downloadingFileContent = true;
      smsService.update();
      getFileContent(theFile).then(fileContent => {
        if (theFile === file) { // user could change it during the file downloading
          media.fileContent = fileContent;
          media.downloadingFileContent = false;
          smsService.update();
        }
      });
    } else {
      resetFile();
    }
  }, [media, file, resetFile, smsService]);

  return (
    <div className="compose-sms-component">
      <span className="desc">
        {mode === AppMode.sms ? 'New SMS from' : 'From'}<br />
        <Link to={routes.home} className={!myNumberService.current?.sms?.ready ? 'red' : ''}>{myNumberService.current?.name}</Link>
      </span>
      <div className="buttons">
        <Button className="p-button-rounded p-button-success" onClick={call} disabled={!myNumberService.current?.calls?.ready || (!contact?.currentNumber && !newPhoneNumber)}>
          <BsTelephone title={`Call ${myNumberService.current?.name || ''}`} />
        </Button>
        <div className="upload">
          <Button className="p-button-rounded" disabled={!hasMmsFeature || (!contact?.currentNumber && !newPhoneNumber)}><BsPaperclip /></Button>
          <input type="file" name="file" onChange={onFileUploadChange} />
        </div>
      </div>
      <div className="draft" ref={inputWrapper}>
        <AutoComplete
          className="template"
          placeholder="Template"
          value={template}
          field="name"
          suggestions={filteredTemplates}
          completeMethod={onTextChange}
          onChange={e => setTemplate(e.value || undefined)}
          dropdown
        />
        <InputTextarea
          placeholder="Message text"
          rows={2}
          className="input-body"
          value={draft?.body || ''}
          onChange={e => setMessageText(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && !e.shiftKey && !e.altKey && e.metaKey && draft && sendSms()}
          autoResize
        />
        {file ?
          <Card className="attachment-card" >
            <div className="filename">{file.name}</div>
            <div className="filesize">{formatFileSize(file.size)}</div>
            <MdOutlineClose onClick={resetFile} />
          </Card>
        : null}
      </div>
      <div className="buttons no-first-btn">
        <Button className="p-button-rounded" onClick={sendSms} disabled={!draft?.body && !media} title="Send the message"><MdSend /></Button>
      </div>
    </div>
  );

}
