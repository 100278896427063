export const getFileContent = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      resolve(e.target.result);
    }
    reader.onerror = (e: any) => {
      const error = e.target.error;
      reject(`Error occured while reading ${file.name}: ${error.message}`);
    }
    reader.readAsBinaryString(file);
  });
}

export const downloadFile = (uri: string) => {
  var link = document.createElement("a");
  // link.download = name;
  link.target = '_blank';
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
