import React, { FunctionComponent, useState, useEffect } from 'react';
import { isAuthenticated } from 'helpers/token';
import { useNavigate } from 'react-router';
import { routes } from 'const/routes';
import { useAuthService } from 'hooks/services/useAuthService';
import { Loader } from 'components/Loader/Loader';

export const AuthGuard: FunctionComponent = (props) => {
  const navigate = useNavigate();
  const authService = useAuthService(); // remain this line (even if you don'e need authService): let it force init AuthService, so that it refreshes the token immediately on init
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    authService.init().then(() => setIsLoading(false));
  });

  if (isLoading) {
    return <Loader />;
  }
  if (!isAuthenticated()) {
    navigate(routes.autoLogin);
    return <div>Access Denied</div>;
  }
  return <>{props.children}</>
};
