import { environment } from 'environments/index';

const { baseUrl } = environment;

export const routes = {
  login: `${baseUrl}login`,
  autoLogin: `${baseUrl}auto-login`,
  logout: `${baseUrl}logout`,
  home: baseUrl,
  chats: `${baseUrl}chats`,
  sms: `${baseUrl}sms`,
  smsTemplates: `${baseUrl}templates`,
  smsList: `${baseUrl}sms-list`,
  autoResponder: `${baseUrl}auto-responder`,
  gallery: `${baseUrl}gallery`,
  calls: `${baseUrl}calls/history`,
  dialer: `${baseUrl}calls/dialer`,
  activeCall: `${baseUrl}calls/active`,
  voicemails: `${baseUrl}voice-mails`,
  greetings: `${baseUrl}greetings`,
};
