import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Contacts } from 'components/Contacts/Contacts';
import { useNavigationService } from 'hooks/services/useNavigationService';
import { AppMode, Page, pageInfo } from 'models/navigation';
import { MainMenu } from '../MainMenu/MainMenu';
import { PageTabs } from '../PageTabs/PageTabs';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { storageKeys } from 'const/storage-keys';
import './AppLayout.scss';
import { useMyNumberService } from 'hooks/services/useMyNumberService';
import { Feature } from 'models/MyNumber';
import { useChatService } from 'hooks/services/useChatService';
import { useCallService } from 'hooks/services/useCallService';

export const AppLayout = ({ mode, page: initialPage, children }: { mode: AppMode, page?: Page, children: any }) => {

  // initialize all services (if not initialized yet)
  const navigate = useNavigate();
  const location = useLocation();
  const navigation = useNavigationService();
  const myNumberService = useMyNumberService();
  useChatService(); // includes myNumberService, contactService, callService, smsService, voicemailService
  // useSmsListService(); // let's load it only when user visits SmsList page

  const remoteAudioRef = useRef<HTMLAudioElement>(null);

  useCallService({ remoteAudioRef });

  useEffect(() => {
    navigation.mode = mode;
    if (initialPage) {
      navigation.page = initialPage;
    }
  }, [navigation, mode, initialPage]);

  useEffect(() => {
    const route = pageInfo[navigation.page].route;
    if (location.pathname === route) {
      return;
    }
    navigate(route);
  }, [location.pathname, navigation.page, navigate]);

  useEffect(() => {
    switch (mode) {
      case AppMode.calls: {
        if (!myNumberService.current?.features.includes(Feature.calls)) {
          myNumberService.setCurrent(myNumberService.myNumbers.find(n => n.features.includes(Feature.calls))!.number);
        }
        break;
      }
      case AppMode.sms: {
        if (!myNumberService.current?.features.includes(Feature.sms)) {
          myNumberService.setCurrent(myNumberService.myNumbers.find(n => n.features.includes(Feature.sms))!.number);
        }
        break;
      }
      case AppMode.chats: {
        if (!myNumberService.current?.features.some(f => [Feature.calls, Feature.sms].includes(f))) {
          myNumberService.setCurrent(myNumberService.myNumbers.find(n => n.features.some(f => [Feature.calls, Feature.sms].includes(f)))!.number);
        }
        break;
      }
    }
  }, [mode, myNumberService]);

  const withContacts = [Page.chats, Page.callHistory, Page.sms, Page.voicemails, Page.gallery].includes(navigation.page);

  const isNavigating = location.pathname !== pageInfo[navigation.page].route;

  return (
    <div className="app-layout-component">
      <audio autoPlay ref={remoteAudioRef} />
      <MainMenu />
      <PageTabs />
      {isNavigating ? null :
      withContacts ?
        <Splitter stateKey={storageKeys.layout.contactsSplitter} stateStorage="local" className="page-content">
          <SplitterPanel size={20} className="auto-flex">
            <Contacts />
          </SplitterPanel>
          <SplitterPanel className="auto-flex">
            <div className="auto-flex">{children}</div>
          </SplitterPanel>
        </Splitter>
      :
        <div className="page-content auto-flex">{children}</div>
      }
    </div>
  );
}
