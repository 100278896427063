import React, { useCallback, useEffect, useMemo } from "react";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { useNavigationService } from "hooks/services/useNavigationService";
import { AppMode, modeInfo, modes } from "models/navigation";
import { Feature } from 'models/MyNumber';
import "./MainMenu.sass";

export const MainMenu = () => {

  const myNumberService = useMyNumberService();
  const navigation = useNavigationService();

  const { mode } = navigation;
  const features = myNumberService.enabledFeatures();

  const modeVisible: Record<AppMode, boolean> = useMemo(() => ({
    [AppMode.home]: true,
    [AppMode.chats]: features.length > 1,
    [AppMode.calls]: features.includes(Feature.calls),
    [AppMode.sms]: features.includes(Feature.sms),
    [AppMode.voicemails]: features.includes(Feature.voicemails),
  }), [features]);

  useEffect(() => {
    if (!modeVisible[mode]) {
      navigation.mode = AppMode.home;
    }
  }, [modeVisible, mode, navigation])

  const selectMode = useCallback((value: AppMode) => () => {
    navigation.mode = value;
  }, [navigation]);

  return (
    <div className="main-menu-component">
      <ul className="main-menu">
        {modes.filter(m => modeVisible[m]).map(m => {
          const { icon: Icon, name } = modeInfo[m];
          return (
            <li key={m} className={`mode-btn ${m === mode && 'active'}`} onClick={selectMode(m)}>
              <Icon /><div>{name}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
