import React from 'react';
import { BsCardChecklist, BsChatText, BsTelephone, BsVoicemail } from 'react-icons/bs';
import { AiOutlineHome } from 'react-icons/ai';
import { routes } from 'const/routes';

export enum AppMode {
  home = 'home',
  chats = 'chats',
  calls = 'calls',
  sms = 'sms',
  voicemails = 'voicemails',
}

export const modes: AppMode[] = [AppMode.home, AppMode.chats, AppMode.calls, AppMode.sms, AppMode.voicemails];

export const modeInfo: Record<AppMode, { icon: React.FC; name: string }> = {
  [AppMode.home]:       { icon: () => <AiOutlineHome />,    name: 'Home' },
  [AppMode.chats]:      { icon: () => <BsCardChecklist />,  name: 'All Chats' },
  [AppMode.calls]:      { icon: () => <BsTelephone />,      name: 'Calls' },
  [AppMode.sms]:        { icon: () => <BsChatText />,       name: 'SMS & MMS' },
  [AppMode.voicemails]: { icon: () => <BsVoicemail />,      name: 'Voicemails' },
};

export enum Page {
  home = 'home',
  chats = 'chats',
  callHistory = 'callHistory',
  dialer = 'dialer',
  activeCall = 'activeCall',
  sms = 'sms',
  smsTemplates = 'smsTemplates',
  smsList = 'smsList',
  autoResponder = 'autoResponder',
  gallery = 'gallery',
  voicemails = 'voicemails',
  greetings = 'greetings',
}

export const pagesByMode: Record<AppMode, Page[]> = {
  [AppMode.home]: [Page.home],
  [AppMode.chats]: [Page.chats],
  [AppMode.calls]: [Page.callHistory, Page.dialer, Page.activeCall],
  [AppMode.sms]: [Page.sms, Page.smsTemplates, Page.smsList, Page.autoResponder, Page.gallery],
  [AppMode.voicemails]: [Page.voicemails, Page.greetings],
}

type RouteUrl = typeof routes[keyof typeof routes];

export const pageInfo: Record<Page, { name: string; route: RouteUrl; }> = {
  [Page.home]: { name: 'Home', route: routes.home },
  [Page.chats]: { name: 'All Chats', route: routes.chats },
  [Page.dialer]: { name: 'Dialer', route: routes.dialer },
  [Page.callHistory]: { name: 'Call history', route: routes.calls },
  [Page.activeCall]: { name: 'Active Call', route: routes.activeCall },
  [Page.sms]: { name: 'SMS & MMS', route: routes.sms },
  [Page.smsTemplates]: { name: 'Templates', route: routes.smsTemplates },
  [Page.smsList]: { name: 'SMS List', route: routes.smsList },
  [Page.autoResponder]: { name: 'Auto Responder', route: routes.autoResponder },
  [Page.gallery]: { name: 'Gallery', route: routes.gallery },
  [Page.voicemails]: { name: 'Voicemails', route: routes.voicemails },
  [Page.greetings]: { name: 'Greetings', route: routes.greetings },
};
