import { storageKeys } from 'const/storage-keys';
import { ApiService, useApiService } from './useApiService';
import { getStorageItem, setStorageItem } from 'helpers/storage';
import { urls } from 'const/urls';
import { MediaService, useMediaService } from './useMediaService';
import { MyNumberService, useMyNumberService } from './useMyNumberService';
import { MyNumber } from 'models/MyNumber';
import { Media } from 'models/Media';
import { Updates, useManageUpdates } from '../useManageUpdates';
import { Id } from 'models/common';
import { delay } from 'helpers/delay';

export interface MyNumberGreeting {
  myNumber: MyNumber;
  greeting?: Media;
}

// Greeting is voicemail auto-reply, can be set for each myNumber separately
export class GreetingService {

  public readonly ready: Promise<void>;
  public isReady = false;
  public updates = 0; // is incremented by update()

  private readonly api: ApiService;
  private readonly myNumberService: MyNumberService;
  private readonly mediaService: MediaService;

  constructor({ api, myNumberService, mediaService }: { api: ApiService, myNumberService: MyNumberService, mediaService: MediaService }) {
    this.api = api;
    this.myNumberService = myNumberService;
    this.mediaService = mediaService;
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
    await Promise.all([this.myNumberService.ready, this.mediaService.ready]);
    await this.loadMyNumberGreetings();
  }

  // returns all myNumbers, with or without greeting
  public async loadMyNumberGreetings(): Promise<MyNumberGreeting[]> {
    interface GreetingsResponse {
      count: number;
      next?: string;
      results: Array<{
        id: number;
        number: string;
        documentId?: number;
        document_id?: number;
        url: string;
      }>;
    }
    interface GreetingStorageResponse {
      number: string;
      documentId: string;
      url: string;
    }

    // load data
    const firstPageUrl = urls.greetings;
    let url = firstPageUrl;
    let greetingResponses: GreetingStorageResponse[] = [];
    do {
      const response = await this.api.get<GreetingsResponse>(url);
      if (url === firstPageUrl) { // page 1
        if (response.count === getStorageItem<number>(storageKeys.voicemails.greetingCount)) {
          greetingResponses = getStorageItem<GreetingStorageResponse[]>(storageKeys.voicemails.greetingResponses) || [];
          break;
        }
      }
      greetingResponses.push(...response.results.map(x => ({
        number: x.number,
        documentId: (x.documentId || x.document_id || '').toString(),
        url: x.url,
      } as GreetingStorageResponse)))
      url = response.next || '';
    } while (url);
    setStorageItem(storageKeys.voicemails.greetingResponses, greetingResponses);
    setStorageItem(storageKeys.voicemails.greetingCount, greetingResponses.length);

    // map greeting responses (eiither just loaded or cached) to myNumbers and greeting media
    const myNumberGreetings: MyNumberGreeting[] = this.myNumberService.myNumbers.map(myNumber => ({ myNumber }));
    for (const x of greetingResponses) {
      const myNumberGreeting = myNumberGreetings.find(ng => ng.myNumber.number === x.number);
      if (!myNumberGreeting) {
        console.warn(`Cannot find my number ${x.number} for greeting documentId ${x.documentId}`);
        continue;
      }
      const media =
        this.mediaService.getMediaById(x.documentId) ||
        this.mediaService.getMediaByUrl(x.url) ||
        this.mediaService.addNewMedia({ id: x.documentId, url: x.url });
      myNumberGreeting.greeting = media;
    }
    return myNumberGreetings;
  }

  async setMyNumberGreeting({ myNumber, mediaId }: { myNumber: string; mediaId?: Id }) {
    await this.ready;
    await delay(1000);
    if (mediaId) {
      await this.api.put(urls.setGreeting.replace(':myNumber', myNumber).replace(':documentId', mediaId));
    } else {
      await this.api.delete(urls.unsetGreeting.replace(':myNumber', myNumber));
    }
    this.update();
  }
}

let greetingService: GreetingService;
let updates: Updates = new Set();

export function useGreetingService(): GreetingService {
  useManageUpdates(updates);
  const api = useApiService();
  const myNumberService = useMyNumberService();
  const mediaService = useMediaService();
  greetingService = greetingService || new GreetingService({ api, myNumberService, mediaService });
  return greetingService;
}
