import React from 'react';
import _ from 'lodash-es';
import { Direction, getMessageDescription, Message, MessageType } from 'models/Message';
import { formatPhoneNumber } from 'helpers/phone';
import { getFormattedTimeFromTimestamp } from 'helpers/date';
import { useMediaService } from 'hooks/services/useMediaService';
import { useCallService } from 'hooks/services/useCallService';
import { useMyNumberService } from 'hooks/services/useMyNumberService';
import { CallIcon } from 'components/CallIcon/CallIcon';
import { Link } from 'react-router-dom';
import { routes } from 'const/routes';
import { setStorageItem } from 'helpers/storage';
import { storageKeys } from 'const/storage-keys';
import { MediaMessageContent } from './MediaMessageContent';
import "./ChatMessage.scss";
import { useSmsService } from 'hooks/services/useSmsService';
import { MdRefresh } from 'react-icons/md';
import { ImSpinner } from 'react-icons/im';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

export const ChatMessage = ({ message }: { message: Message }) => {

  const mediaService = useMediaService();
  const myNumberService = useMyNumberService();
  const callService = useCallService();
  const smsService = useSmsService();

  const media = message.mediaId ? mediaService.getMediaById(message.mediaId) : undefined;

  const callBack = (call: Message) => () => {
    if (myNumberService.current?.number !== call.myNumber) {
      myNumberService.setCurrent(call.myNumber);
    }
    setStorageItem(storageKeys.calls.dialingNumber, call.contactNumber, sessionStorage);
    callService.dial(call.contactNumber);
  }

  const sendMessage = (message: Message) => () => {
    smsService.sendMessage(message);
  }

  return (
    <li className={`chat-message-component message-type-${message.type} ${message.direction === Direction.out && 'my-message'} ${message.failed ? ' failed' : ''}`}>
      <div className="message-content">
        {message.type === MessageType.voicemail ?
          <audio controls={true} src={media?.url} />

        : message.type === MessageType.sms || message.type === MessageType.mms ? <>
          {message.body ? _.flatten(getMessageDescription(message).split('\n').map((messageLine, i) => [
            <span key={i}>{messageLine}</span>,
            <br key={`${i}_br`} />,
          ])) : null}
          {media ? <MediaMessageContent media={media} /> : null}
        </>
        
        : message.type === MessageType.call ?
          <div className="clickable call-message" onClick={callBack(message)}>
            <CallIcon call={message} />
            <div>{getMessageDescription(message)}</div>
          </div>

        : null
        }
        {message.failed &&
          <div className="fail-description"><BsFillExclamationTriangleFill /> {message.failed === true ? 'Message sending failed.' : message.failed}</div>
        }
      </div>
      <Link to={routes.home} className="my-number">{formatPhoneNumber(message.myNumber)}</Link>
      <div className="time">{getFormattedTimeFromTimestamp(message.at)}</div>
      <div className={`status status-${message.sending ? 'sending' : message.failed ? 'failed' : 'ok'}`}>{
        message.sending ? <ImSpinner /> :
        message.failed ? <MdRefresh onClick={sendMessage(message)} title="Retry sending failed message again" /> :
        null
      }</div>
    </li>
  );

}