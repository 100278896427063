import { Loader } from "components/Loader/Loader";
import { generateGuid } from "helpers/guid";
import { useTemplateService } from "hooks/services/useTemplatesService";
import { Template } from "models/Template";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Splitter, SplitterPanel } from "primereact/splitter";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import "./Templates.scss";

export const SmsTemplates = () => {

  const templateService = useTemplateService();
  const templates = templateService.templates;

  const [searchPattern, setSearchPattern] = useState('');
  const [currentTemplate, setCurrentTemplate] = useState<Template | undefined>(undefined);


  // filter by search pattern
  let rx: RegExp | null = null;
  try {
    rx = new RegExp(`(^|\\b)${searchPattern}`, 'i');
  } catch {
    // ignore bad rx
  }
  let filteredTemplates = templateService.templates;
  if (searchPattern) {
    filteredTemplates = filteredTemplates.filter(c => (rx && rx.test(c.name || '')) || (rx && rx.test(c.body || '')));
  }

  const newTemplateNameWrapper = useRef<HTMLDivElement>(null);
  const focus = useCallback(() => {
    setTimeout(() => {
      const inputElement = newTemplateNameWrapper.current?.querySelector('input') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
        inputElement.selectionStart = inputElement.value.length;
      }
    }, 100);
  }, [newTemplateNameWrapper]);
  useEffect(() => {
    if (newTemplateNameWrapper) {
      setTimeout(() => {
        focus();
      }, 100);
    }
  }, [newTemplateNameWrapper, focus]);

  const [newTemplateVisible, setNewTemplateVisible] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');

  const newTemplateClick = useCallback(() => {
    setNewTemplateVisible(true);
  }, []);

  const onNewSTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTemplateName(e.target.value);
  }

  const hideNewTemplate = () => {
    setNewTemplateVisible(false);
    setNewTemplateName('');
  }

  const addNewTemplate = async () => {
    if (!newTemplateName.trim()) {
      return;
    }
    const id = generateGuid();
    templates.push({
      id,
      name: newTemplateName,
      body: '',
    });
    templateService.update();
    setNewTemplateName('');
    setNewTemplateVisible(false);
    setTimeout(() => {
      setCurrentTemplate(templateService.templates.find(t => t.id === id));
    }, 50);
  }

  const saveTemplate = () => {
    const template = templateService.templates.find(t => t.id === currentTemplate?.id);
    if (!template || !currentTemplate) {
      return;
    }
    template.name = currentTemplate.name;
    template.body = currentTemplate.body;
    templateService.update();
  }

  console.log(currentTemplate);

  return (
    <div className="sms-templates-component auto-flex">
      <Splitter>
        <SplitterPanel size={20} className="templates-panel auto-flex">
         <div className="search-panel">
            <div>
              <span className="p-input-icon-left">
                <BsSearch />
                <InputText className="p-inputtext-lg" value={searchPattern} onChange={(e) => setSearchPattern(e.target.value)} placeholder="Search" />
              </span>
            </div>
          </div>
          {(!templateService.isReady) && <Loader />}
          <ul className="templates" onClick={() => setCurrentTemplate(undefined)}>
            {filteredTemplates.map(template => {
              return (
                <li
                  className={`template ${currentTemplate?.id === template.id && 'selected'}`}
                  key={template.id}
                  onClick={e => {
                    setCurrentTemplate(template);
                    e.stopPropagation();
                  }}
                >
                  <div className="name">{template.name}</div>
                  <div className="description">{template.body}</div>
                </li>
              );
            })}
            {filteredTemplates.length === 0 ? <em>No templates found</em> : null}
            <div ref={newTemplateNameWrapper}>
              {newTemplateVisible &&
                <li className="contact new-contact">
                  <div className="name">
                    <InputText
                      value={newTemplateName}
                      onChange={onNewSTemplateNameChange}
                      onKeyDown={e =>
                        (e.key === 'Enter' && addNewTemplate()) ||
                        (e.key === 'Escape' && hideNewTemplate())
                      }
                      placeholder="Template name"
                      title="Template name"
                    />
                  </div>
                </li>
              }
            </div>
          </ul>
          <Button
            className="p-button-rounded new-template-btn"
            onClick={newTemplateClick}
          ><GoPlus /></Button>
        </SplitterPanel>
        <SplitterPanel size={20} className="auto-flex">
          {currentTemplate && <>
            <InputText
              value={currentTemplate.name}
              onChange={e => setCurrentTemplate({ ...currentTemplate, name: e.target.value })}
              onBlur={() => saveTemplate()}
            />
            <InputTextarea
              placeholder="Message text"
              rows={2}
              className="input-body"
              value={currentTemplate.body}
              onChange={e => setCurrentTemplate({ ...currentTemplate, body: e.target.value })}
              onBlur={() => saveTemplate()}
              autoResize
            />
          </>}
        </SplitterPanel>
      </Splitter>

    </div>
  );

};
