import React, { useCallback, useEffect, useState } from "react";
import { Divider } from 'primereact/divider';
import { MyNumberGreeting, useGreetingService } from "hooks/services/useGreetingService";
import { Loader } from "components/Loader/Loader";
import { Media } from "models/Media";
import { useMediaService } from "hooks/services/useMediaService";
import { GreetingCard } from "./GreetingCard";
import { MyNumberGreetingCard } from "./MyNumberGreetingCard";
import "./Greetings.sass";

export const Greetings = () => {

  const greetingService = useGreetingService();
  const mediaService = useMediaService();

  const [numberGreetings, setNumberGreetings] = useState<MyNumberGreeting[] | undefined>();
  const [greetings, setGreetings] = useState<Media[] | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (greetingService.isReady && !numberGreetings) {
      greetingService.loadMyNumberGreetings().then(result => setNumberGreetings(result));
    }
  }, [greetingService.isReady, greetingService, numberGreetings]);

  useEffect(() => {
    if (mediaService.isReady && !greetings) {
      setGreetings(mediaService.media.filter(m => m.mimeType?.startsWith('audio/')));
    }
  }, [mediaService.isReady, mediaService, greetings]);

  const setMyNumberGreeting = useCallback(async ({ myNumber, url }: { myNumber: string; url?: string }) => {
    const index = (numberGreetings || []).findIndex(x => x.myNumber.number === myNumber);
    const newNumberGreetings = numberGreetings || []; // [...(numberGreetings || [])];
    const greeting = url ? (greetings || []).find(g => g.url === url) : undefined;
    newNumberGreetings.splice(index, 1, { myNumber: newNumberGreetings[index].myNumber, greeting });
    setNumberGreetings(newNumberGreetings);
    setLoading(true);
    try {
      await greetingService.setMyNumberGreeting({ myNumber, mediaId: greeting?.id });
    } finally {
      setLoading(false);
    }
  }, [numberGreetings, greetings, greetingService]);
  
  if (!greetings || !numberGreetings) {
    return <Loader />;
  }

  return (
    <div className="greetings-component auto-flex">
      <p>Please drag phone numbers over audio greetings</p>
      {loading && <Loader />}
      <div className="greetings-panel">
        <div className="greetings">
          {greetings.map(greeting =>
            <GreetingCard
              key={greeting.id}
              greeting={greeting}
              setMyNumberGreeting={setMyNumberGreeting}
            />
          )}
        </div>
        <Divider layout="vertical" />
        <div className="numbers">
          {numberGreetings.map(myNumberGreeting =>
            <MyNumberGreetingCard
              key={myNumberGreeting.myNumber.number}
              myNumberGreeting={myNumberGreeting}
              unsetGreeting={setMyNumberGreeting}
            />
          )}
        </div>
      </div>
    </div>
  );

};
