export const urls = {
  accountInfo: 'me',
  customData: 'communicator/app/:customerNumber',
  callNumbers: 'mobile_app/registrations',
  smsNumbers: 'sms/:customerNumber/list',
  mmsNumbers: 'mms/:customerNumber/list',
  smsLists: 'sms/:customerNumber/lists',
  smsListDetails: 'sms/lists/:listId',
  smsListVariables: 'sms/lists/:listId/variables',
  createSmsList: 'sms/lists', // POST { label, customer_number }
  addSmsListNumber: 'sms/lists/:listId', // POST { number, meta_data }
  removeSmsListNumber: 'sms/lists/:listId/:number', // DELETE
  addSmsListVariable: 'sms/lists/:listId/variables', // POST { variable }
  smsCampaigns: 'sms/campaigns',
  createSmsCampaign: 'sms/campaigns', // POST { customer_number, label, body }
  updateSmsCampaign: 'sms/campaigns/:campaignId', // POST { label, body }
  smsCampaignDetails: 'sms/campaigns/:campaignId',
  runSmsCampaign: 'sms/campaigns/:campaignId', // PUT { list_id, from_numbers } & optional { coding: 'UCS2' | 'ASCII' | 'UTF8', max_message_count, frequency: 'seconds' | 'minutes' | 'hours', frequency_value }; throttling is "{max_message_count} messages per {frequency_value} {frequency}"
  pauseSmsCampaign: 'sms/campaigns/:campaignId/:runId/stop', // PUT
  resumeSmsCampaign: 'sms/campaigns/:campaignId/:runId/resume', // PUT optional { from_numbers, coding: 'UCS2' | 'ASCII' | 'UTF8' }
  smsCampaignRuns: 'sms/campaign_runs',
  autoResponders: 'sms/autoresponders/:myNumber',
  createAutoResponder: 'sms/autoresponders/:myNumber', // POST { stopword_match_type, stopword, response, active }
  updateAutoResponder: 'sms/autoresponders/:myNumber/:id', // POST { stopword_match_type, stopword, response, active }
  mediaDocuments: 'documents/:customerNumber',
  messageThreads: 'mobile_app/threads/:myNumber',
  threadMessages: 'mobile_app/messages/:threadId',
  sendSms: 'sms/:myNumber', // POST { to_number, message, reset_unread_count: 1 }
  sendMms: 'mms/:myNumber', // POST { to_number, message, attachment: binary }
  mmsMessages: 'mms/:myNumber/inbound',
  voicemails: 'voicemail/received/:myNumber',
  greetings: 'voicemail/greetings',
  setGreeting: 'voicemail/greetings/:myNumber/:documentId', // PUT
  unsetGreeting: 'voicemail/greetings/:myNumber', // DELETE
};
