import { Token } from 'models/Token';
import { storageKeys } from 'const/storage-keys';
import { getStorageItem } from './storage';

export const anonymousToken: Token = { accessToken: '', refreshToken: '' };

export function getCustomerNumber(): number {
  return getStorageItem(storageKeys.auth.customerNumber) || 0;
}

export function getToken(): Token {
  return getStorageItem(storageKeys.auth.token) || anonymousToken;
}

export function isAuthenticated(): boolean {
  const token = getToken();
  return Boolean(token?.accessToken);
}
