import { environment as defaultEnvironment } from './default';

export const environment = {
  ...defaultEnvironment,
  production: true,
  sip: {
    ...defaultEnvironment.sip,
    server: 'edge.sip.onsip.com',
    port: 80,
  }
};
