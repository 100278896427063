import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSmsListService } from 'hooks/services/useSmsListService';
import { useContactService } from 'hooks/services/useContactService';
import { InputText } from 'primereact/inputtext';
import { Loader } from 'components/Loader/Loader';
import { usePrevious } from 'hooks/usePrevious';
import './FormFields.scss';
import { Button } from 'primereact/button';

export const FormFields = () => {
  
  const contactService = useContactService();
  const smsListService = useSmsListService();

  const list = smsListService.currentSmsList;
  const contact = contactService.current;

  const contactData = (list?.contactData || []).find(c => (contact?.numbers || []).includes(c.number));

  const [fieldValues, setFieldValues] = useState<Record<string, string>>(contactData?.fields || {});
  const [newFieldName, setNewFieldName] = useState<string | undefined>(undefined);

  const setFieldVariable = (variable: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValues({ ...fieldValues, [variable]: e.target.value });
  };

  const commitField = useCallback((variable: string) => async () => {
    await smsListService.updateFieldValue(list!, contactData!, variable, fieldValues[variable]);
  }, [contactData, fieldValues, list, smsListService]);

  const ids = [list?.id || '', contact?.id || ''].join('+');
  const prevIds = usePrevious(ids);

  useEffect(() => {
    if (ids === prevIds) {
      return;
    }
    Promise.all(Object.keys(fieldValues).map(variable => commitField(variable)));
    setTimeout(() => {
      setFieldValues(contactData?.fields || {});
    });
  }, [ids, prevIds, contactData?.fields, commitField, fieldValues]);
 
  const newFieldNameWrapper = useRef<HTMLDivElement>(null);

  const showNewField = () => {
    setNewFieldName('');
    setTimeout(() => {
      newFieldNameWrapper.current?.querySelector('input')?.focus();
    }, 100);
  }

  const addNewField = () => {
    if (!newFieldName || !newFieldName.trim()) {
      setNewFieldName(undefined);
      return;
    }
    smsListService.addNewField(newFieldName);
    setNewFieldName(undefined);
  }

  if (!list || !contactData) {
    return null;
  }

  return (
    <div className="form-fields-component">
      {smsListService.fieldsLoading && <div className="loader"><Loader /></div>}
      {list.variables.map(variable => {
        const id = [list.id, contactData.number, variable].join('/');
        return (
          <div className="field" key={variable}>
            <label htmlFor={id} className="block">{variable}</label>
            <InputText
              id={id}
              key={variable}
              className="p-inputtext-lg"
              value={fieldValues[variable] || ''}
              onChange={setFieldVariable(variable)}
              onBlur={commitField(variable)}
            />
          </div>
        );
      })}
      {newFieldName === undefined ?
        <div className="add-new-field-btn">
          <Button className="p-button-rounded" onClick={showNewField}>Add new field</Button>
        </div>
      :
        <div className="field">
          <div ref={newFieldNameWrapper} className="field-name-wrapper">
            <InputText
              value={newFieldName}
              placeholder="Field name"
              onChange={e => setNewFieldName(e.target.value)}
              onBlur={addNewField}
            />
          </div>
        </div>
      }
    </div>
  );
}
