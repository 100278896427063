import { getStorageItem, setStorageItem } from 'helpers/storage';
import { Feature, features, MyNumber } from 'models/MyNumber';
import { storageKeys } from 'const/storage-keys';
import { ApiService, useApiService } from './useApiService';
import _ from 'lodash-es';
import { urls } from 'const/urls';
import { formatPhoneNumber } from 'helpers/phone';
import { Updates, useManageUpdates } from '../useManageUpdates';
import { PhoneNumber } from 'models/common';
import { AuthService, useAuthService } from './useAuthService';

interface MyNumbersResponse {
  count: number;
  next: string;
  results: Array<{
    number: string;
    features: {
      sms: boolean,
      mms: boolean,
      e911: boolean,
      cnam_outbound: boolean,
      cnam_inbound: boolean,
      fax: boolean,
      voicemail: boolean,
      call_forwarding: boolean,
      conference: boolean,
      call_proc: boolean,
      queue: boolean,
    },
  }>;
}

export class MyNumberService {

  public myNumbers: MyNumber[] = getStorageItem(storageKeys.myNumbers) || [];
  private _current: PhoneNumber | undefined;

  public readonly ready: Promise<void>;
  public updates = 0; // is incremented by update()
  public isReady = false;
  
  private readonly api: ApiService;
  private readonly authService: AuthService;

  constructor({ api, authService }: { api: ApiService, authService: AuthService }) {
    this.api = api;
    this.authService = authService;
    this.hasFeature = this.hasFeature.bind(this);
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    this.updates++;
    const myNumbers = this.myNumbers.map(n => ({
      ...n,
      ...(n.calls ? { calls: { ready: false } } : {}),
      ...(n.sms ? { sms: { ready: false } } : {}),
      ...(n.mms ? { mms: { ready: false } } : {}),
    }));
    setStorageItem(storageKeys.myNumbers, myNumbers);
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
    const cachedMyNumbers: MyNumber[] | undefined = getStorageItem<MyNumber[]>(storageKeys.myNumbers);
    this.myNumbers = cachedMyNumbers || [];
    const [callNumbers, smsNumbers, mmsNumbers] = await Promise.all([
      this.loadCallNumbers(),
      this.loadSMSNumbers(),
      this.loadMMSNumbers(),
    ]);
    let numbers: MyNumber[] = callNumbers;
    for (const myNumber of [...smsNumbers, ...mmsNumbers]) {
      const existedIndex = numbers.findIndex(n => n.number === myNumber.number);
      if (existedIndex !== -1) {
        numbers[existedIndex] = this.merge(numbers[existedIndex], myNumber);
      } else {
        numbers.push(myNumber);
      }
    }
    this.myNumbers = numbers;
    this.update();
  }

  public setCurrent(number: PhoneNumber) {
    this._current = number;
    this.update();
  }

  public get current(): MyNumber | undefined {
    if (!this._current) {
      return undefined;
    }
    return this.myNumbers.find(n => n.number === this._current);
  }

  public hasFeature(feature: Feature) {
    return this.myNumbers.some(n => n.features.includes(feature));
  }

  public enabledFeatures(): Feature[] {
    return features.filter(feature => this.hasFeature(feature));
  }

  private async loadCallNumbers(): Promise<MyNumber[]> {
    interface RegistrationsResponse {
      registrations: Array<{
        lineId: number;
        displayName: string; // "4243051070"
        uri: string; // "4243051070@webrtc.apeironsys.com";
        authorizationUser: string; // "4243051070",
        password: string;
      }>;
    }
    const response = await this.api.get<RegistrationsResponse>(urls.callNumbers);
    return response.registrations.map(x => ({
      number: x.authorizationUser,
      name: formatPhoneNumber(x.authorizationUser),
      features: [Feature.calls],
      calls: {
        ready: false,
        sipPassword: x.password,
      },
    }));
  }

  private async loadSMSNumbers(): Promise<MyNumber[]> {
    await this.authService.ready;
    const { customerNumber } = this.authService.account;
    const result: MyNumber[] = [];
    const firstPageUrl = urls.smsNumbers.replace(':customerNumber', String(customerNumber));
    let url = firstPageUrl;
    do {
      const response = await this.api.get<MyNumbersResponse>(url);
      result.push(...response.results.map(x => ({
        number: x.number,
        name: formatPhoneNumber(x.number),
        features: [
          ...(x.features.sms        ? [Feature.sms]       : []),
          ...(x.features.mms        ? [Feature.mms]       : []),
          ...(x.features.voicemail  ? [Feature.voicemails] : []),
        ],
        sms: {
          ready: false,
        },
      })));
      url = response.next || '';
    } while (url);
    return result;
  }

  private async loadMMSNumbers(): Promise<MyNumber[]> {
    await this.authService.ready;
    const { customerNumber } = this.authService.account;
    const result: MyNumber[] = [];
    const firstPageUrl = urls.smsNumbers.replace(':customerNumber', String(customerNumber));
    let url = firstPageUrl;
    do {
      const response = await this.api.get<MyNumbersResponse>(urls.mmsNumbers.replace(':customerNumber', String(customerNumber)));
      result.push(...response.results.map(x => ({
        number: x.number,
        name: formatPhoneNumber(x.number),
        features: [
          ...(x.features.sms        ? [Feature.sms]       : []),
          ...(x.features.mms        ? [Feature.mms]       : []),
          ...(x.features.voicemail  ? [Feature.voicemails] : []),
        ],
        mms: {
          ready: false,
        },
      })));
      url = response.next || '';
    } while (url);
    return result;
  }

  private merge(myNumber1: MyNumber, myNumber2: MyNumber): MyNumber {
    if (myNumber1.number !== myNumber2.number) {
      throw new Error(`Cannot merge two different my numbers ${myNumber1.number} and ${myNumber2.number}`);
    }
    let result: MyNumber = {
      number: myNumber1.number,
      name: myNumber1.name,
      features: _.uniq([...myNumber1.features, ...myNumber2.features]),
    };
    if (myNumber1.calls || myNumber2.calls) {
      result.calls = {
        ready: myNumber1.calls?.ready || myNumber2.calls?.ready || false,
        sipPassword: myNumber1.calls?.sipPassword || myNumber2.calls?.sipPassword || '',
        sipAgent: myNumber1.calls?.sipAgent || myNumber2.calls?.sipAgent,
      };
    }
    if (myNumber1.sms || myNumber2.sms) {
      result.sms = {
        ready: myNumber1.sms?.ready || myNumber2.sms?.ready || false,
      };
    }
    if (myNumber1.mms || myNumber2.mms) {
      result.mms = {
        ready: myNumber1.mms?.ready || myNumber2.mms?.ready || false,
      };
    }
    return result;
  }
}

let myNumberService: MyNumberService;
let updates: Updates = new Set();

export function useMyNumberService(): MyNumberService {
  useManageUpdates(updates);
  const api = useApiService();
  const authService = useAuthService();
  myNumberService = myNumberService || new MyNumberService({ api, authService });
  return myNumberService;
}
