import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Loader } from 'components/Loader/Loader';
import { Button } from 'primereact/button';
import { GoPlus } from 'react-icons/go';
import { useSmsListService } from 'hooks/services/useSmsListService';
import './SmsLists.scss';
import { BsSearch } from 'react-icons/bs';

export const SmsLists = () => {
  
  const smsListService = useSmsListService();
  
  const [searchPattern, setSearchPattern] = useState('');
  
  const selectSmsList = useCallback(smsList => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    smsListService.currentSmsList = smsList;
  }, [smsListService]);

  // filter by search pattern
  let rx: RegExp | null = null;
  try {
    rx = new RegExp(`(^|\\b)${searchPattern}`, 'i');
  } catch {
    // ignore bad rx
  }
  let filteredSmsLists = smsListService.smsLists;

  if (searchPattern) {
    const digitalSearchPattern = searchPattern.replace(/[^0-9]/, '');
    filteredSmsLists = filteredSmsLists.filter(c =>
      (rx && rx.test(c.name || '')) ||
      (digitalSearchPattern && c.contactData.some(c => c.number.replace(/[^0-9]/, '').includes(digitalSearchPattern)))
    );
  }

  const [newSmsListVisible, setNewSmsListVisible] = useState(false);
  const [newSmsListName, setNewSmsListName] = useState('');

  const newSmsListClick = useCallback(() => {
    setNewSmsListVisible(true);
  }, []);

  const onNewSmsListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSmsListName(e.target.value);
  }

  const hideNewSmsList = () => {
    setNewSmsListVisible(false);
    setNewSmsListName('');
  }

  const newSmsListNameWrapper = useRef<HTMLDivElement>(null);
  const focus = useCallback(() => {
    setTimeout(() => {
      const inputElement = newSmsListNameWrapper.current?.querySelector('input') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
        inputElement.selectionStart = inputElement.value.length;
      }
    }, 100);
  }, [newSmsListNameWrapper]);
  useEffect(() => {
    if (newSmsListNameWrapper) {
      setTimeout(() => {
        focus();
      }, 100);
    }
  }, [newSmsListVisible, focus]);

  const addNewSmsList = async () => {
    if (!await smsListService.addNewSmsList(newSmsListName)) {
      return;
    }
    setNewSmsListName('');
    setNewSmsListVisible(false);
  }


  return (
    <div className="sms-lists-component">
      <div className="search-panel">
        <div>
          <span className="p-input-icon-left">
            <BsSearch />
            <InputText className="p-inputtext-lg" value={searchPattern} onChange={(e) => setSearchPattern(e.target.value)} placeholder="Search" />
          </span>
        </div>
      </div>
      {(!smsListService.isReady) && <Loader />}
      <ul className="contacts" onClick={selectSmsList(undefined)}>
        {filteredSmsLists.map(smsList => {
          return (
            <li
              className={`contact ${smsListService.currentSmsList?.id === smsList.id && 'selected'}`}
              key={smsList.id}
              onClick={selectSmsList(smsList)}
            >
              <div className="name">{smsList.name}</div>
              <div className="description">
                {smsList.contactData.length === 0 ? 'empty list' :
                  `${smsList.contactData.length} phone number${smsList.contactData.length > 1 ? 's' : ''}`
                }
              </div>
            </li>
          );
        })}
        {filteredSmsLists.length === 0 ? <em>No contact lists found</em> : null}
        <div ref={newSmsListNameWrapper}>
          {newSmsListVisible &&
            <li className="contact new-contact">
              <div className="name">
                <InputText
                  value={newSmsListName}
                  onChange={onNewSmsListNameChange}
                  onKeyDown={e =>
                    (e.key === 'Enter' && addNewSmsList()) ||
                    (e.key === 'Escape' && hideNewSmsList())
                  }
                  placeholder="SMS list name"
                  title="SMS list name"
                />
              </div>
            </li>
          }
        </div>
      </ul>
      <Button
        className="p-button-rounded new-contact-btn"
        onClick={newSmsListClick}
        title="Add a new list of SMS campaign recepients"
      ><GoPlus /></Button>
    </div>
  );
}
