import _ from 'lodash-es';
import { storageKeys } from 'const/storage-keys';
import { ApiService, useApiService } from './useApiService';
import { getStorageItem, setStorageItem } from 'helpers/storage';
import { urls } from 'const/urls';
import { Id } from 'models/common';
import { MediaService, useMediaService } from './useMediaService';
import { Direction, Message, MessageType } from 'models/Message';
import { ContactService, useContactService } from './useContactService';
import { MyNumberService, useMyNumberService } from './useMyNumberService';
import { Feature } from 'models/MyNumber';
import { sortByAtDescFunc } from 'helpers/collection';
import { Updates, useManageUpdates } from '../useManageUpdates';

export class VoicemailService {

  private voicemails: Message[]; // init value is set in constructor

  // WARNING: don't forget to care about data index integrity!
  private voicemailById: Record<string, Message>; // init value is set in constructor
  private voicemailsByContactId: Record<string, Message[]>; // init value is set in constructor

  public readonly ready: Promise<void>;
  public isReady = false;
  public updates = 0; // is incremented by update()

  private readonly api: ApiService;
  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;
  private readonly mediaService: MediaService;

  constructor({ api, myNumberService, contactService, mediaService }: { api: ApiService, myNumberService: MyNumberService, contactService: ContactService, mediaService: MediaService }) {
    this.api = api;
    this.myNumberService = myNumberService;
    this.contactService = contactService;
    this.mediaService = mediaService;

    // init values
    this.voicemails = getStorageItem(storageKeys.voicemails.messages) || [];
    this.voicemailById = _.fromPairs(this.voicemails.filter(m => m.entityId).map(m => [m.entityId, m]));
    this.voicemailsByContactId = _.fromPairs(_.toPairs(_.groupBy(
      this.voicemails.map(m => ({ vm: m, contactId: contactService.getContactByNumber(m.contactNumber)?.id || '' })),
      (x: { vm: Message, contactId: Id }) => x.contactId
    )).map(([contactId, items]: [Id, { vm: Message, contactId: Id }[]]) => [contactId, items.map(x => x.vm)]));
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    setStorageItem(storageKeys.voicemails.messages, this.voicemails);
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
    await Promise.all([this.myNumberService.ready, this.contactService.ready, this.mediaService.ready]);
    await this.loadVoicemails();
  }

  private async loadVoicemails(): Promise<void> {
    interface VoicemailsResponse {
      count: number;
      next?: string;
      results: Array<{
        id: number;
        timestamp: string; // WARNING: that's not seconds (as in other endpoints)! instead, it's date.toISOString()
        from_number: string;
        to_number: string;
        download_url: string;
      }>;
    }
    const myVoicemailNumbers = this.myNumberService.myNumbers.filter(n => n.features.includes(Feature.voicemails));
    await Promise.all(myVoicemailNumbers.map(async myNumber => {
      const firstPageUrl = urls.voicemails.replace(':myNumber', myNumber.number);
      let url = firstPageUrl;
      do {
        const response = await this.api.get<VoicemailsResponse>(url);
        if (url === firstPageUrl) { // page 1
          if (response.count === getStorageItem<number>(storageKeys.voicemails.count)) {
            break;
          }
          setStorageItem(storageKeys.voicemails.count, response.count || 0);
        }
        const newVoicemails: Message[] = response.results.filter(x => !this.voicemailById[x.id.toString()]).map(x => {
          const at = new Date(x.timestamp).getTime();
          const media =
            this.mediaService.getMediaByUrl(x.download_url) ||
            this.mediaService.addNewMedia({ id: `voice-mail-${x.id}`, url: x.download_url });
          return {
            myNumber: x.to_number,
            contactNumber: x.from_number,
            direction: Direction.in,
            type: MessageType.voicemail,
            entityId: x.id.toString(),
            at,
            sentAt: at,
            mediaId: media.id,
          } as Message;
        });
        for (const voicemail of newVoicemails) {
          this.addNewVoicemail(voicemail);
        }
        url = response.next || '';
      } while (url);
    }));
    this.voicemails.sort(sortByAtDescFunc);
    for (const contactId of Object.keys(this.voicemailsByContactId)) {
      this.voicemailsByContactId[contactId].sort(sortByAtDescFunc);
    }
  }

  addNewVoicemail(voicemail: Message) {
    this.voicemails.push(voicemail);
    if (voicemail.entityId) {
      this.voicemailById[voicemail.entityId] = voicemail;
    }
    const contact =
      this.contactService.getContactByNumber(voicemail.contactNumber) ||
      this.contactService.addNewContact(voicemail.contactNumber, voicemail);
    contact.hasVoicemails = true;
    if (contact?.id) {
      this.voicemailsByContactId[contact.id] = this.voicemailsByContactId[contact.id] || [];
      this.voicemailsByContactId[contact.id].push(voicemail);
    }
  }
  
  public getMessagesByContactId(contactId: Id): Message[] {
    return this.voicemailsByContactId[contactId] || [];
  }

  get totalMessageCount(): number {
    return this.voicemails.length;
  }
}

let voicemailService: VoicemailService;
let updates: Updates = new Set();

export function useVoicemailService(): VoicemailService {
  useManageUpdates(updates);
  const api = useApiService();
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  const mediaService = useMediaService();
  voicemailService = voicemailService || new VoicemailService({ api, myNumberService, contactService, mediaService });
  return voicemailService;
}
