import React from 'react';
import { useDrag } from 'react-dnd'
import { Card } from 'primereact/card';
import { Media } from 'models/Media';
import { MyNumberGreeting } from 'hooks/services/useGreetingService';

export const GreetingCard = ({ greeting, setMyNumberGreeting }: { greeting: Media; setMyNumberGreeting: ({ myNumber, url }: { myNumber: string; url: string }) => void }) => {
  const [, drag] = useDrag(() => ({
    type: 'greeting',
    item: greeting,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<MyNumberGreeting>();
      if (dropResult?.myNumber) {
        setMyNumberGreeting({ myNumber: dropResult.myNumber.number, url: item.url! })
      }
    },
  }))

  return (
    <div ref={drag}>
      <Card className="greeting-card" >
        <audio controls={true} src={greeting.url} />
      </Card>
    </div>
  );
}