import { Feature } from 'models/MyNumber';
import _ from 'lodash-es';
import { MyNumberService, useMyNumberService } from './useMyNumberService';
import { Direction, Message, MessageType } from 'models/Message';
import { AudioService, useAudioService } from './useAudioService';
import { storageKeys } from 'const/storage-keys';
import { ContactService, useContactService } from './useContactService';
import { ApiService, useApiService } from './useApiService';
import { MessageThread } from 'models/MessageThread';
import { getStorageItem, setStorageItem } from 'helpers/storage';
import { urls } from 'const/urls';
import { DateTime, Id, PhoneNumber } from 'models/common';
import { environment } from 'environments';
import { getToken } from 'helpers/token';
import { secondsToUnixTime } from 'helpers/date';
import decodeContent from 'helpers/decoder';
import { sortByAtDescFunc } from 'helpers/collection';
import { generateGuid } from 'helpers/guid';
import { Updates, useManageUpdates } from '../useManageUpdates';
import { MediaService, useMediaService } from './useMediaService';
import { Media } from 'models/Media';
import { showToast } from 'helpers/toast';
import { formatPhoneNumber } from 'helpers/phone';
import { AuthService, useAuthService } from './useAuthService';

interface WebSocketMessage {
  jsonrpc: '2.0';
  data: {
    type: 'event_sms_received' | 'event_sms_sent';
    from: string;
    to: string;
    mms: boolean;
    msg_id: string;
    segment_count: number;
    content: string;
    coding: number;
    attachment: null;
    timestamp: number;
    thread_id: number;
  };
}

export class SmsService {

  private drafts: Record<Id, Message> = {}; // key is contactId
  private messageThreads: MessageThread[] = getStorageItem(storageKeys.sms.threads) || [];
  private smsHistory: Record<Id, Message[]> = {}; // key is contactId; messages are sorted `at desc`

  // WARNING: don't forget to care about data index integrity!
  private messageById: Record<Id, Message> = _.fromPairs(_.flatten(Object.values(this.smsHistory)).filter(m => m.entityId).map(m => [m.entityId, m]));
  private messagesByNumbers: Record<Id, Message[]> = // key is [myNumber, contactNumber].join('-')
    _.groupBy(_.flatten(Object.values(this.smsHistory)), (m: Message) => `${m.myNumber}-${m.contactNumber}`);
  private messageThreadById: Record<Id, MessageThread> = _.fromPairs(this.messageThreads.map(t => [t.id, t]));
  private messageThreadsByContactId: Record<Id, MessageThread[]> = _.groupBy(this.messageThreads, (t: MessageThread) => t.contactId);

  private ws: WebSocket | undefined;

  public readonly ready: Promise<void>;
  public isReady = false;
  public updates = 0; // is incremented by update()

  private readonly api: ApiService;
  private readonly audio: AudioService;
  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;
  private readonly mediaService: MediaService;
  private readonly authService: AuthService;

  constructor({ api, audio, myNumberService, contactService, mediaService, authService }: { api: ApiService, audio: AudioService, myNumberService: MyNumberService, contactService: ContactService, mediaService: MediaService, authService: AuthService }) {
    this.api = api;
    this.audio = audio;
    this.myNumberService = myNumberService;
    this.contactService = contactService;
    this.mediaService = mediaService;
    this.authService = authService;
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    const since: DateTime = new Date().getTime() - environment.sms.storageLimitDays * 24 * 60 * 60 * 1000;
    const smsHistory = _.cloneDeep(this.smsHistory);
    for (const contactId of Object.keys(smsHistory)) {
      smsHistory[contactId] = smsHistory[contactId].filter(m =>  m.at >= since);
    }
    setStorageItem(storageKeys.sms.threads, this.messageThreads);
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
    localStorage.removeItem(storageKeys.sms.messages);
    for (const thread of this.messageThreads) {
      thread._allMessagesWereLoaded = thread._allMessagesWereLoaded || thread.allMessagesLoaded;
      thread.latestMessagesLoaded = thread.allMessagesLoaded = false;
    }
    await Promise.all([this.myNumberService.ready, this.contactService.ready]);
    const mySmsNumbers = this.myNumberService.myNumbers.filter(n => n.features.includes(Feature.sms));
    await Promise.all([
      this.loadMessageThreads(),
      this.initWebSocket(),
    ]);
    for (const myNumber of mySmsNumbers) {
      myNumber.sms!.ready = true;
    }
    this.myNumberService.update();
    // setInterval(() => this.addTestIncomingMessage(), 5000);
  }

  // WARNINIG: if messages are not cached enough or cache is stale, then it loads from DB and updates the cache
  public async getMessagesByContactId(contactId: Id): Promise<Message[]> {
    const since: DateTime = new Date().getTime() - environment.sms.storageLimitDays * 24 * 60 * 60 * 1000;
    if (this.smsHistory[contactId]) {
      return this.smsHistory[contactId];
    }
    const contact = this.contactService.getContactById(contactId);
    if (!contact) {
      return [];
    }
    interface MessagesResponse {
      messages: Array<{
        message_id: Id;
        timestamp: string;
        direction: Direction;
        mms: boolean;
        message: string;
      }>;
      has_more: boolean;
    }
    const threads = this.messageThreadsByContactId[contactId] || [];
    const staleThreads = threads.filter(t => !t.latestMessagesLoaded);
    let updateRequired = staleThreads.length > 0;
    for (const thread of staleThreads) {
      const numbersKey = [thread.myNumber, thread.contactNumber].join('-');
      let hasMore: boolean;
      let oldestDateTime: DateTime;
      let page = 1;
      do {
        const url = `${urls.threadMessages.replace(':threadId', thread.id)}${page > 1 ? `?page=${page}` : ''}`;
        const response = await this.api.get<MessagesResponse>(url);
        hasMore = response.has_more;
        oldestDateTime = secondsToUnixTime(response.messages.slice(-1)[0]?.timestamp || 0);
        for (const x of response.messages.filter(m => !m.mms)) {
          const at = secondsToUnixTime(x.timestamp);
          const message: Message = {
            myNumber: thread.myNumber,
            contactNumber: thread.contactNumber,
            direction: x.direction,
            type: MessageType.sms,
            entityId: x.message_id,
            at,
            sentAt: at,
            body: x.message,
          };
          const existedMessage =
            this.messageById[message.entityId!] ||
            (this.messagesByNumbers[numbersKey] || []).find(m =>
              Math.abs(m.at - message.at) < 100 &&
              !m.entityId?.startsWith('in-') &&
              !m.entityId?.startsWith('out-') &&
              m.body === message.body &&
              m.direction === message.direction
            );
          if (existedMessage && (existedMessage.entityId !== message.entityId)) {
            existedMessage.entityId = message.entityId; // replace GUID-like id from WebSocket with message_id from database
          }
          if (!existedMessage) {
            this.smsHistory[contactId] = this.smsHistory[contactId] || [];
            this.smsHistory[contactId].push(message);
            this.messageById[message.entityId!] = message;
            this.messagesByNumbers[numbersKey] = this.messagesByNumbers[numbersKey] || [];
            this.messagesByNumbers[numbersKey].push(message);
          }
        }
        page++;
      } while (hasMore && oldestDateTime > since);
      thread.latestMessagesLoaded = true;
      if (!hasMore) {
        thread.allMessagesLoaded = true;
      }
    }
    const failedMessages = _.uniqBy(this.authService.account.customData.failedMessages.filter(m => contact.numbers.includes(m.contactNumber)), (m: Message) => m.entityId);
    const loadedFailedMessages = this.smsHistory[contactId].filter(m => m.failed);
    if (failedMessages.length !== loadedFailedMessages.length) {
      updateRequired = true;
      for (let i = 0; i < failedMessages.length; i++) {
        const failedMessage = failedMessages[i];
        const existedMessage = loadedFailedMessages.find(m =>
          m.at === failedMessage.at &&
          m.myNumber === failedMessage.myNumber &&
          m.contactNumber === failedMessage.contactNumber &&
          m.body === failedMessage.body
        );
        if (existedMessage) {
          failedMessages[i] = existedMessage;
          continue;
        }
        this.smsHistory[contactId].push(failedMessage);
      }
      const currentFailedMessages = this.smsHistory[contactId].filter(m => m.failed);
      if (failedMessages.length !== currentFailedMessages.length) {
        this.authService.account.customData.failedMessages.push(..._.differenceBy(currentFailedMessages, failedMessages, (m: Message) => m.entityId));
        await this.authService.saveCustomData();
      }
    }
    if (updateRequired) {
      this.smsHistory[contactId].sort(sortByAtDescFunc);
      this.update();
    }
    
    return this.smsHistory[contactId] || [];
  }

  public createDraftMessage(contactId: Id = this.contactService.current?.id || '', newPhoneNumber = ''): void {
    const key = contactId || 'new';
    if (this.drafts[key]) {
      return;
    }
    const myNumber = this.myNumberService.current?.features?.includes(Feature.sms)
      ? this.myNumberService.current.number
      : this.myNumberService.myNumbers.find(n => n.features.includes(Feature.sms))?.number;
    if (!myNumber) {
      throw new Error('No numbers found with enabled SMS feature');
    }
    const contact = this.contactService.getContactById(contactId)!
    if (contactId && !contact) {
      throw new Error(`Contact with ID=${contactId} not found`);
    }
    this.drafts[key] = {
      myNumber,
      contactNumber: contact?.currentNumber || newPhoneNumber || '',
      direction: Direction.out,
      type: MessageType.sms,
      at: new Date().getTime(),
    } as Message;
    this.update();
  }

  setNewDraftContactNumber(contactNumber: PhoneNumber) {
    if (!this.drafts['new'] || this.drafts['new'].contactNumber === contactNumber) {
      return;
    }
    this.drafts['new'].contactNumber = contactNumber;
    this.update();
  }

  public updateDraftForMms(contactId: Id = this.contactService.current?.id || ''): boolean {
    const draft = this.drafts[contactId];
    if (!draft) {
      return false;
    }
    if (this.myNumberService.myNumbers.find(n => n.number === draft.myNumber)?.features?.includes(Feature.mms)) {
      return true;
    }
    const myNumber = this.myNumberService.current?.features?.includes(Feature.mms)
      ? this.myNumberService.current.number
      : this.myNumberService.myNumbers.find(n => n.features.includes(Feature.mms))?.number;
    if (!myNumber) {
      return false;
    }
    draft.myNumber = myNumber;
    this.update();
    return true;
  }

  public getDraftMessage(contactId: Id = this.contactService.current?.id || ''): Message | undefined {
    return this.drafts[contactId || 'new'] || undefined;
  }

  public async sendDraftMessage(contactId: Id = this.contactService.current?.id || ''): Promise<Message> {
    if (!contactId) {
      throw new Error('No contact provided for sending message');
    }
    const message = this.drafts[contactId];
    if (!message?.body?.trim() && !message.mediaId) {
      throw new Error(`message or attachment is required to send the message`);
    }
    message.at = new Date().getTime();
    smsService.addNewMessage(message);
    delete this.drafts[contactId];
    await this.sendMessage(message);
    return message;
  }

  public async sendMessage(message: Message): Promise<void> {
    if (!message.failed) {
      _.remove(this.authService.account.customData.failedMessages, message);
      this.authService.saveCustomData();
    }
    delete message.failed;
    message.sending = true;
    this.update();
    interface MessageSentResponse {
      message_id: string;
      sms_thread_id: number;
      date_sent: string;
      delivered: boolean;
      delivery_info: any;
      message?: string; // in case of fail
    }
    const { myNumber, contactNumber } = message;
    let file: Blob | undefined = undefined;
    if (message.mediaId) {
      const media: Media = this.mediaService.getMediaById(message.mediaId)!;
      file = new Blob([media.fileContent!], { type: media.mimeType });
    }
    try {
      const response = file
      ? await this.api.post<MessageSentResponse>(urls.sendMms.replace(':myNumber', myNumber), {}, { form: {
        to_number: contactNumber,
        message: message.body || '',
        attachment: file,
      } })
      : await this.api.post<MessageSentResponse>(urls.sendSms.replace(':myNumber', myNumber), {
        to_number: contactNumber,
        message: message.body,
        reset_unread_count: 1,
      });
      if (!response.message_id) {
        console.error(response);
        throw new Error(response.message);
      }
      console.log(response);
      message.entityId = response.message_id;
      message.at = message.sentAt = new Date(response.date_sent).getTime();
      this.ensureThread({ myNumber, contactNumber, threadId: response.sms_thread_id.toString(), lastMessage: message });
    } catch (e: any) {
      message.failed = e.message && typeof e.message === 'string' ? e.message : true;
    } finally {
      delete message.sending;
    }
    if (message.failed) {
      this.authService.account.customData.failedMessages.push(message);
      this.authService.saveCustomData();
    }
    this.update();
  }

  private async loadMessageThreads(): Promise<void> {
    interface MessageThreadsResponse {
      threads: Array<{
        identifier: Id;
        direction: 'inbound' | 'outbound';
        peer_number: string;
        unread_messages: number;
        last_message: string; // message body
        password: string;
        last_message_timestamp: string; // seconds.toString()
      }>;
    }
    const mySmsNumbers = this.myNumberService.myNumbers.filter(n => n.features.includes(Feature.sms));
    const responses = await Promise.all(mySmsNumbers.map(myNumber =>
      this.api.get<MessageThreadsResponse>(urls.messageThreads.replace(':myNumber', myNumber.number))
    ));
    const threads: MessageThread[] = _.flatten(responses.map((response, numberIndex) => {
      const myNumber = mySmsNumbers[numberIndex];
      return response.threads.map(x => {
        const contactNumber = x.peer_number;
        const at = secondsToUnixTime(x.last_message_timestamp);
        const lastMessage: Message = {
          myNumber: myNumber.number,
          contactNumber,
          direction: x.direction === 'inbound' ? Direction.in : Direction.out,
          type: MessageType.sms,
          at,
          sentAt: at,
          body: x.last_message,
        };
        const contact = this.contactService.getContactByNumber(contactNumber);
        const cachedLastMessage = (this.smsHistory[contact?.id || ''] || [])[0];
        const cachedThread = this.messageThreadById[x.identifier];
        const latestMessagesLoaded = Boolean(cachedLastMessage) &&
          lastMessage.at === cachedLastMessage.at &&
          lastMessage.direction === cachedLastMessage.direction &&
          lastMessage.body === cachedLastMessage.body;
        const allMessagesLoaded = latestMessagesLoaded && Boolean(cachedThread?.allMessagesLoaded);
        return {
          id: x.identifier,
          myNumber: myNumber.number,
          contactId: '',
          contactNumber,
          lastMessage,
          latestMessagesLoaded,
          allMessagesLoaded,
          _allMessagesWereLoaded: allMessagesLoaded,
        };
      }) as MessageThread[];
    }));

    for (const thread of threads) {
      const contact =
        this.contactService.getContactByNumber(thread.contactNumber) ||
        this.contactService.addNewContact(thread.contactNumber, thread.lastMessage);
      contact.hasSms = true;
      thread.contactId = contact.id;
    }

    this.messageThreads = threads;
    this.messageThreadById = _.fromPairs(this.messageThreads.map(t => [t.id, t]));
    this.messageThreadsByContactId = _.groupBy(this.messageThreads, (t: MessageThread) => t.contactId);
    this.update();
  }

  private async initWebSocket() {
    const accessToken = getToken()?.accessToken;
    if (!accessToken) {
      return;
    }
    this.ws = new WebSocket(environment.sms.webSocket.replace('{ACCESS_TOKEN}', accessToken));
    this.ws.onopen = () => {
      console.log('SMS WebSocket opened');

      this.ws!.onmessage = (event: { data: string }) => {
        const webSocketMessage = JSON.parse(event.data) as WebSocketMessage;
        if (!webSocketMessage.data.mms) {
          this.addIncomingMessage(webSocketMessage);
        }
      };
      this.ws!.onerror = event => console.log('On SMS WebSocket error', event);
      this.ws!.onclose = event => console.log('On SMS WebSocket close', event);
    };
  }
  private addNewMessage(message: Message): Message {
    const { myNumber, contactNumber } = message;
    if (message.type && message.type !== MessageType.sms) {
      throw new Error(`Cannot add SMS message with messageType ${message.type}`);
    }
    if (!myNumber || !contactNumber) {
      throw new Error('myNumber and contactNumber should be provided for the new message');
    }
    message.entityId = message.entityId || generateGuid();
    this.ensureThread({ myNumber, contactNumber, lastMessage: message });
    const contact = this.contactService.getContactByNumber(contactNumber)!;
    this.smsHistory[contact.id] = this.smsHistory[contact.id] || [];
    this.smsHistory[contact.id].unshift(message);
    this.messageById[message.entityId] = message;
    const numbersKey = [myNumber, contactNumber].join('-');
    this.messagesByNumbers[numbersKey] = this.messagesByNumbers[numbersKey] || [];
    this.messagesByNumbers[numbersKey].push(message);
    this.update();
    return message;
  }

  private addIncomingMessage(webSocketMessage: WebSocketMessage) {
    console.log('WebSocket INCOMING SMS MESSAGE', webSocketMessage);
    const { data } = webSocketMessage;
    const direction =
      data.type === 'event_sms_received' ? Direction.in :
      data.type === 'event_sms_sent' ? Direction.out :
      '';
    if (!direction) {
      return;
    }
    if (direction === Direction.in) {
      this.audio.play('incomingSms');
    }
    const at = secondsToUnixTime(data.timestamp);
    const myNumber = direction === Direction.out ? data.from : data.to;
    const contactNumber = direction === Direction.in ? data.from : data.to;
    if (direction === Direction.out && this.messagesByNumbers[`${myNumber}-${contactNumber}`].some(m => m.entityId === data.msg_id)) {
      // that's a message we've just created and sent, prevent duplication
      return;
    }
    const body = decodeContent(data.content, data.coding);
    const newMessage: Message = {
      myNumber,
      contactNumber,
      direction,
      type: MessageType.sms,
      entityId: data.msg_id, // WARNING! it's not equal to message_id from GET GET mobile_app/messages/:threadId. This one looks like guid, while regular message id looks like "out-2350021"
      at,
      sentAt: at,
      body,
    };
    this.ensureThread({ myNumber, contactNumber, lastMessage: newMessage });
    const contact = this.contactService.getContactByNumber(contactNumber)!;
    this.smsHistory[contact.id] = this.smsHistory[contact.id] || [];
    this.smsHistory[contact.id].unshift(newMessage);
    if (newMessage.entityId) {
      this.messageById[newMessage.entityId] = newMessage;
    }
    const numbersKey = [myNumber, contactNumber].join('-');
    this.messagesByNumbers[numbersKey] = this.messagesByNumbers[numbersKey] || [];
    this.messagesByNumbers[numbersKey].push(newMessage);
    if (direction === Direction.in) {
      showToast({
        severity: 'info',
        summary: `SMS from ${formatPhoneNumber(contactNumber)}`,
        detail: body,
      });
    }
    this.update();
  }

  // lastMessage is assigned only if thread didn't exist before
  // it also creates contact, if it didn't exist, and also updates lastMessage in contact. But again - only if thread didn't exist, and of course only if contact doesn't already have lastMessage newer than provided.
  private ensureThread({ myNumber, contactNumber, threadId = '', lastMessage }:
    { myNumber: string; contactNumber: string; threadId?: Id; lastMessage: Message }
  ): MessageThread {
    let contact =
      this.contactService.getContactByNumber(contactNumber) ||
      this.contactService.addNewContact(contactNumber, lastMessage);
    contact.hasSms = true;
    let thread = (this.messageThreadsByContactId[contact.id] || []).find(t => t.myNumber === myNumber && t.contactNumber === contactNumber);
    const newOne = !thread;
    if (!thread) {
      thread = {
        id: threadId,
        myNumber,
        contactId: contact.id,
        contactNumber,
        lastMessage,
        latestMessagesLoaded: true,
        allMessagesLoaded: true,
      };
      this.messageThreads.push(thread);
      if (thread.id) {
        this.messageThreadById[thread.id] = thread;
      }
      this.messageThreadsByContactId[contact.id] = this.messageThreadsByContactId[contact.id] || [];
      this.messageThreadsByContactId[contact.id].push(thread);
    }
    if (!contact.lastSmsMessage || contact.lastSmsMessage.at < lastMessage.at) {
      contact.lastSmsMessage = lastMessage;
      if (!contact.lastMessage || contact.lastMessage.at < lastMessage.at) {
        contact.lastMessage = lastMessage;
      }
      this.contactService.update();
    }
    if (newOne) {
      this.update();
    }
    return thread;
  }

  get totalMessageCount(): number {
    return _.flatten(Object.values(this.smsHistory)).length;
  }

  private addTestIncomingMessage() {
    const myNumber = '4243051070';
    const contactNumber = '8332782634';
    const isIncoming = Math.random() < 0.5;
    this.addIncomingMessage({
      jsonrpc: '2.0',
      data: {
        type: isIncoming ? 'event_sms_received' : 'event_sms_sent',
        from: isIncoming ? contactNumber : myNumber,
        to: isIncoming ? myNumber : contactNumber,
        mms: false,
        msg_id: Math.random().toString(),
        timestamp: Math.round(new Date().getTime() * 1000),
        thread_id: 1717785,
        content: 'Test message ' + Math.round(Math.random() * 100),
        coding: -1,
        segment_count: 1,
        attachment: null,
      },
    });
  }
}

let smsService: SmsService;
let updates: Updates = new Set();

export function useSmsService(): SmsService {
  useManageUpdates(updates);
  const api = useApiService();
  const audio = useAudioService();
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  const mediaService = useMediaService();
  const authService = useAuthService();
  smsService = smsService || new SmsService({ api, audio, myNumberService, contactService, mediaService, authService });
  return smsService;
}
