import { Updates, useManageUpdates } from '../useManageUpdates';
import { AuthService, useAuthService } from './useAuthService';
import { Template } from 'models/Template';

export class TemplatesService {

  public templates: Template[] = [];

  public readonly ready: Promise<void>;
  public isReady = false;
  public updates = 0; // is incremented by update()

  private readonly auth: AuthService;

  constructor({ auth }: { auth: AuthService }) {
    this.auth = auth;
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    this.auth.account.customData.templates = this.templates;
    this.auth.saveCustomData(); // no await here!
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
    await this.auth.ready;
    this.templates = this.auth.account.customData.templates;
    this.update();
  }
}

let templateService: TemplatesService;
let updates: Updates = new Set();

export function useTemplateService(): TemplatesService {
  useManageUpdates(updates);
  const auth = useAuthService();
  templateService = templateService || new TemplatesService({ auth });
  return templateService;
}
