import { IdNumeric } from "./common";
import { Contact } from "./Contact";
import { Message } from "./Message";
import { Template } from "./Template";

// key is { customerNumber, email }
export interface Account {
  customerNumber: IdNumeric;
  email: string;
  firstName: string;
  lastName: string;
  balance: number;
  customData: {
    templates: Template[];
    contacts: Contact[];
    failedMessages: Message[];
  };
}

export const emptyAccount: Account = {
  customerNumber: 0,
  email: '',
  firstName: '',
  lastName: '',
  balance: 0,
  customData: {
    templates: [],
    contacts: [],
    failedMessages: [],
  },
};
