import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { formatPhoneNumber, toDigitalNumber } from 'helpers/phone';
import { useContactService } from 'hooks/services/useContactService';
import { useNavigationService } from 'hooks/services/useNavigationService';
import { AppMode, Page } from 'models/navigation';
import { LastMessageDescription } from './LastMessage';
import { getDateOrTimeText } from 'helpers/date';
import { Loader } from 'components/Loader/Loader';
import { useSmsService } from 'hooks/services/useSmsService';
import { useVoicemailService } from 'hooks/services/useVoicemailService';
import { useCallService } from 'hooks/services/useCallService';
import { Button } from 'primereact/button';
import { GoPlus } from 'react-icons/go';
import { showToast } from 'helpers/toast';
import { SmsList } from 'models/SmsList';
import { Contact } from 'models/Contact';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { BsSearch } from 'react-icons/bs';
import _ from 'lodash-es';
import './Contacts.scss';
import { FaUserEdit } from 'react-icons/fa';

interface ContactItem extends Contact {
  checked?: boolean;
}

export const Contacts = ({ list, toggleContact }: { list?: SmsList, toggleContact?: (contact: Contact, checked: boolean) => void } = {}) => {
  
  const navigation = useNavigationService();
  const contactService = useContactService();
  const callService = useCallService();
  const smsService = useSmsService();
  const voiceMailService = useVoicemailService();
  
  const [searchPattern, setSearchPattern] = useState('');
  
  const selectContact = useCallback(contact => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    contactService.current = contact;
    contactService.update();
  }, [contactService]);

  const mode = navigation.mode || AppMode.chats;

  // filter by search pattern
  let rx: RegExp | null = null;
  try {
    rx = new RegExp(`(^|\\b)${searchPattern}`, 'i');
  } catch {
    // ignore bad rx
  }
  const digitalSearchPattern = searchPattern.replace(/[^0-9]/, '');
  let filteredContacts: ContactItem[] = !searchPattern ? contactService.contacts : contactService.contacts.filter(c =>
    (rx && rx.test(c.name || '')) ||
    (digitalSearchPattern && c.numbers.some(nuumber => nuumber.replace(/[^0-9]/, '').includes(digitalSearchPattern)))
  );

  if (list) {
    for (const contact of filteredContacts) {
      contact.checked = list.contactData.some(cd => contact.numbers.includes(cd.number));
    }
  }
  filteredContacts = _.sortBy(filteredContacts, [
    (c: ContactItem) => Boolean(list) && !c.checked,
    (c: ContactItem) => -(c.lastMessage?.at || 0),
    (c: ContactItem) => c.numbers.every(number => c.name !== formatPhoneNumber(number)),
    (c: ContactItem) => c.name,
  ]);

  // filter by modeFilter
  if (mode === AppMode.sms && !list) {
    filteredContacts = filteredContacts.filter(c => c.hasSms || c.hasMms);
  }
  if (mode === AppMode.calls) {
    filteredContacts = filteredContacts.filter(c => c.hasCalls);
  }
  if (mode === AppMode.voicemails) {
    filteredContacts = filteredContacts.filter(c => c.hasVoicemails);
  }

  const [newContactVisible, setNewContactVisible] = useState(false);
  const [newContactNumber, setNewContactNumber] = useState('');

  const newContactClick = useCallback(() => {
    if (mode === AppMode.calls) {
      navigation.page = Page.dialer;
      return;
    }
    if (navigation.page === Page.chats || navigation.page === Page.sms) {
      contactService.current = undefined;
      contactService.update();
      return;
    }
    setNewContactVisible(true);
  }, [navigation, mode, contactService]);

  const onNewContactNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const digital = toDigitalNumber(e.target.value);
    const existedContact = contactService.contacts.find(c => c.numbers.includes(digital));
    if (existedContact) {
      contactService.setCurrentNumber({ contact: existedContact, currentNumber: digital });
      contactService.current = existedContact;
      hideNewContact();
      return;
    }
    setNewContactNumber(digital);
  }

  const hideNewContact = () => {
    setNewContactVisible(false);
    setNewContactNumber('');
  }

  const newContactNumberWrapper = useRef<HTMLDivElement>(null);
  const focus = useCallback(() => {
    setTimeout(() => {
      const inputElement = newContactNumberWrapper.current?.querySelector('input') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
        inputElement.selectionStart = inputElement.value.length;
      }
    }, 100);
  }, [newContactNumberWrapper]);
  useEffect(() => {
    if (newContactVisible) {
      setTimeout(() => {
        focus();
      }, 100);
    }
  }, [newContactVisible, focus]);

  const addNewContact = () => {
    if (newContactNumber.length !== 10) {
      showToast({
        severity: 'warn',
        summary: 'Invalid phone number',
        detail: 'Please enter valid 10-digit phone number',
      });
      return;
    }
    contactService.addNewContact(newContactNumber);
    setNewContactNumber('');
    setNewContactVisible(false);
  }

  const onCheckClick = (contact: ContactItem) => async (x: CheckboxChangeParams) => {
    if (!list) {
      return;
    }
    x.originalEvent.stopPropagation();
    if (toggleContact) {
      toggleContact(contact, x.checked);
    }
  }

  useEffect(() => {
    const element = document.querySelector('.contacts-component .selected');
    if (element) {
      element.scrollIntoView();
    }
  }, [contactService.current?.id])

  const loading =
    ([AppMode.chats, AppMode.sms].includes(mode) && !smsService.isReady) ||
    ([AppMode.chats, AppMode.voicemails].includes(mode) && !voiceMailService.isReady);

  return (
    <div className="contacts-component">
      <div className="search-panel">
        <div>
          <span className="p-input-icon-left">
            <BsSearch />
            <InputText className="p-inputtext-lg" value={searchPattern} onChange={(e) => setSearchPattern(e.target.value)} placeholder="Search" />
          </span>
        </div>
      </div>
      {loading && <Loader />}
      <ul
        className={`contacts ${list && filteredContacts.some(c => c.checked !== undefined) && 'checkable'}`}
        onClick={selectContact(undefined)}
      >
        {navigation.page === Page.gallery &&
          <li className={`contact ${contactService.current ? '' : 'selected'}`}>
            <div className="name">Show All</div>
          </li>
        }
        {filteredContacts.map(contact => {
          const lastMessage =
            mode === AppMode.calls ? callService.getMessagesByContactId(contact.id)[0] :
            mode === AppMode.voicemails ? voiceMailService.getMessagesByContactId(contact.id)[0] :
            mode === AppMode.sms ? (contact.lastSmsMessage || contact.lastMessage) :
              contact.lastMessage;
          return (
            <li
              className={`contact ${contactService.current?.id === contact.id && 'selected'}`}
              key={contact.id}
              onClick={selectContact(contact)}
            >
              <div className="check">
                <Checkbox onChange={onCheckClick(contact)} checked={contact.checked}></Checkbox>
              </div>
              <div className="name">{contact.name || formatPhoneNumber(contact.numbers.join(', '))}</div>
              <div className="last-message">
                {lastMessage ? <LastMessageDescription lastMessaage={lastMessage} /> : null}
              </div>
              <div className="date-time">
                {lastMessage ? getDateOrTimeText(lastMessage.at) : null}
              </div>
              <div className="manage-contact">
                <FaUserEdit />
              </div>
            </li>
          );
        })}
        {filteredContacts.length === 0 ? <em>No contacts found</em> : null}
        <div ref={newContactNumberWrapper}>
          {newContactVisible &&
            <li className="contact new-contact">
              <div className="name">
                <InputText
                  value={formatPhoneNumber(newContactNumber)}
                  onChange={onNewContactNumberChange}
                  onKeyDown={e =>
                    (e.key === 'Enter' && addNewContact()) ||
                    (e.key === 'Escape' && hideNewContact())
                  }
                  placeholder="Phone number"
                  title="Phone number"
                />
              </div>
              <div className="new-contact-buttons">
                <span className="p-buttonset">
                  <Button label="OK" onClick={addNewContact} disabled={!newContactNumber} />
                  <Button label="Cancel" className="p-button-secondary" onClick={hideNewContact} />
                </span>
              </div>
            </li>
          }
        </div>
      </ul>
      <Button
        className="p-button-rounded new-contact-btn"
        onClick={newContactClick}
        title="Message to a new contact"
      ><GoPlus /></Button>
    </div>
  );
}
