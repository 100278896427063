import { useState } from 'react';
import { Id } from 'models/common';
import { Message } from 'models/Message';
import { ContactService, useContactService } from './useContactService';
import { MyNumberService, useMyNumberService } from './useMyNumberService';
import { CallService, useCallService } from './useCallService';
import { SmsService, useSmsService } from './useSmsService';
import { useVoicemailService, VoicemailService } from './useVoicemailService';
import { sortByAtDescFunc } from 'helpers/collection';
import { Updates, useManageUpdates } from '../useManageUpdates';
import { MmsService, useMmsService } from './useMmsService';

// Chat service is Call service + SMS service + Voicemail service, it provides messagesByContactId where messages are retrieved and merged from different services
export class ChatService {

  // WARNING: please reset messagesByContactId completely to {} on any update from other services
  private messagesByContactId: Record<Id, Promise<Message[]>> = {}; // key is contactId; message array is sorted `at desc`

  public readonly ready: Promise<void>;
  public isReady = false;
  public updates = 0; // is incremented by update()

  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;
  private readonly callService: CallService;
  private readonly smsService: SmsService;
  private readonly mmsService: MmsService;
  private readonly voicemailService: VoicemailService;

  constructor({ myNumberService, contactService, callService, smsService, mmsService, voicemailService }: { myNumberService: MyNumberService, contactService: ContactService, callService: CallService, smsService: SmsService, mmsService: MmsService, voicemailService: VoicemailService }) {
    this.myNumberService = myNumberService;
    this.contactService = contactService;
    this.callService = callService;
    this.smsService = smsService;
    this.mmsService = mmsService;
    this.voicemailService = voicemailService;
    this.ready = this.init().then((() => {
      this.isReady = true;
      this.update();
    }));
  }

  public update() {
    this.messagesByContactId = {};
    this.updates++;
    for (const updateDispatch of Array.from(updates)) {
      updateDispatch(this.updates);
    }
  }

  public async init(): Promise<void> {
    await Promise.all([
      this.myNumberService.ready,
      this.contactService.ready,
      this.callService.ready,
      this.smsService.ready,
      this.mmsService.ready,
      this.voicemailService.ready,
    ]);
  }

  public async getMessagesByContactId(contactId: Id, force = false): Promise<Message[]> {
    const initialUpdates = this.updates;
    if (force || !this.messagesByContactId[contactId]) {
      this.messagesByContactId[contactId] = new Promise(async resolve => {
        const messages = [
          ...this.callService.getMessagesByContactId(contactId),
          ...await this.smsService.getMessagesByContactId(contactId),
          ...this.mmsService.getMessagesByContactId(contactId),
          ...this.voicemailService.getMessagesByContactId(contactId),
        ];
        if (this.updates !== initialUpdates) {
          resolve(await this.getMessagesByContactId(contactId, true));
          return;
        }
        messages.sort(sortByAtDescFunc);
        resolve(messages);
      });
    }
    return this.messagesByContactId[contactId];
  }

  get totalMessageCount(): number {
    return (
      this.smsService.totalMessageCount +
      this.mmsService.totalMessageCount +
      this.callService.totalMessageCount +
      this.voicemailService.totalMessageCount
    );
  }
}

let chatService: ChatService;
let updates: Updates = new Set();

export function useChatService(): ChatService {
  useManageUpdates(updates);
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  const callService = useCallService();
  const smsService = useSmsService();
  const mmsService = useMmsService();
  const voicemailService = useVoicemailService();

  chatService = chatService || new ChatService({ myNumberService, contactService, callService, smsService, mmsService, voicemailService });

  const [otherServicesUdpates, setOtherServicesUdpates] = useState(myNumberService.updates + contactService.updates + callService.updates + smsService.updates + mmsService.updates + voicemailService.updates);
  const currentOtherServicesUdpates = myNumberService.updates + myNumberService.updates + contactService.updates + callService.updates + smsService.updates + mmsService.updates + voicemailService.updates;
  if (currentOtherServicesUdpates !== otherServicesUdpates) {
    setOtherServicesUdpates(currentOtherServicesUdpates);
    chatService.update();
  }
  return chatService;
}
